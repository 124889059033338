
import { Helmet } from "react-helmet";

import Img0 from "images/stepImg.png";


import Img1 from "images/img16.png";
import Img2 from "images/img29.png";

import Img3 from "images/img24.png";
import Img4 from "images/img22.png";

import Img5 from "images/img34.png";
import Img6 from "images/img35.png";

import Img7 from "images/img44.png";
import Img8 from "images/img45.png";


const NotreGammeDeSoupe = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Notre gamme de soupe | Conserverie Bertin</title>
            </Helmet>

            {/* BEFORE FIRST  */}
            <div className="bg-[#FFF]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8">

                            <h1 className="uppercase text-[45px] text-[#777] text-center font-julius font-[600] mt-4">Nos soupes</h1>
                            <div className="flex justify-center">
                                <img src={Img0} className="w-1/1 mt-10" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* BEFORE FIRST  */}



            {/* FIRST  */}
            <div className="bg-[#742d31]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8">

                            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 gap-4">
                                <div className="order-last lg:order-first md:order-first xl:order-first">
                                    <img src={Img1} />
                                </div>
                                <div>
                                    <img src={Img2} />
                                    <div className="mt-6">
                                        <h1 className="text-[white] text-center font-normal font-julius text-[24px] font-[600]">Ingrédients</h1>
                                        <p className="text-[white] text-center font-[400] text-[16px] leading-[25px] mt-5">Eau, assortiment de <p className="font-bold inline">poissons </p>
                                            44%, concentré de tomates, fumet de légumes (pommes de terre, vin blanc (<p className="font-bold inline">sulfites</p>
                                            ), oignons, carottes, farine de riz, poireaux, beurre (<p className="font-bold inline">lactose</p>
                                            )), fécule de pommes de terre, sel, ail, épices diverses.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* FIRST  */}

            {/* SECOND  */}
            <div className="bg-[#aa2c41]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8">

                            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 gap-4">

                                <div>
                                    <img src={Img4} />
                                    <div className="mt-6">
                                        <h1 className="text-[white] text-center font-normal font-julius text-[24px] font-[600]">Ingrédients</h1>
                                        <p className="text-[white] text-center font-[400] text-[16px] leading-[25px] mt-5">Eau, assortiment de <p className="font-bold inline">poissons </p>
                                            38%, <p className="font-bold inline"> langoustines </p>
                                            10%, concentré de tomates, fumet de légumes (pommes de terre, vin blanc (<p className="font-bold inline">sulfites</p>
                                            ), oignons, carottes, farine de riz, poireaux, beurre (<p className="font-bold inline">lactose</p>
                                            )), fécule de pommes de terre, sel, ail, épices diverses, huile d'olive.</p>
                                    </div>
                                </div>

                                <div>
                                    <img src={Img3} />
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SECOND  */}

            {/* THIRD  */}
            <div className="bg-[#16a2b9]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8">

                            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 gap-4">

                                <div className="order-last lg:order-first md:order-first xl:order-first">
                                    <img src={Img5} />
                                </div>

                                <div>
                                    <img src={Img6} />
                                    <div className="mt-6">
                                        <h1 className="text-[white] text-center font-normal font-julius text-[24px] font-[600]">Ingrédients</h1>
                                        <p className="text-[white] text-center font-[400] text-[16px] leading-[25px] mt-5">Eau, assortiment de <p className="font-bold inline">poissons </p>
                                            38%, <p className="font-bold inline">crabes </p>
                                            13%, concentré de tomates, fumet de légumes (pommes de terre, vin blanc (<p className="font-bold inline">sulfites</p>
                                            ), oignons, carottes, farine de riz, poireaux, beurre (<p className="font-bold inline">lactose</p>
                                            )), fécule de pommes de terre, sel, ail, épices.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* THIRD  */}

            {/* FOUR  */}
            <div className="bg-[#e8973e]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8">

                            <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 gap-4">

                                <div>
                                    <img src={Img7} />
                                    <div className="mt-6">
                                        <h1 className="text-[white] text-center font-normal font-julius text-[24px] font-[600]">Ingrédients </h1>
                                        <p className="text-[white] text-center font-[400] text-[16px] leading-[25px] mt-5">Eau, assortiments de <p className="font-bold inline">poissons </p>
                                            32%, <p className="font-bold inline"> moules </p>
                                            19%, crème épaisse (<p className="font-bold inline">lactose</p>
                                            ), fumet de légumes (pommes de terre, vin blanc (<p className="font-bold inline">sulfites</p>
                                            ), oignons, carottes, farine de riz, poireaux, beurre (<p className="font-bold inline">lactose</p>
                                            )), concentré de tomates, fécule de pomme de terre, sel, ail, épices diverses.
                                        </p>
                                    </div>
                                </div>

                                <div>
                                    <img src={Img8} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* FOUR  */}


        </div>
    );
}

export default NotreGammeDeSoupe;
