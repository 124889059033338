import { FC, useState } from "react";

import img1 from "images/caroussel_1.jpeg";

import Next from "shared/NextPrev/Next";
import Prev from "shared/NextPrev/Prev";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { COLORS } from "config/theme";

interface HeroDataType {
    image: string;
    heading: string;
    subHeading: string;
    btnLink: string;
}
export interface SectionHeroProps {
    className?: string;
}

const DATA: HeroDataType[] = [
    {
        image: img1,
        heading: "La cuisine c'est quand les choses ont le goût de ce qu'elles sont !",
        subHeading: "la révolution bio et locale est en marche !",
        btnLink: "/",
    }
];

let TIME_OUT: NodeJS.Timeout | null = null;

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
    // =================
    const [indexActive, setIndexActive] = useState(0);
    const [isRunning, toggleIsRunning] = useBoolean(true);

    useInterval(
        () => {
            handleAutoNext();
        },
        isRunning ? 5500 : null
    );


    const handleAutoNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
    };

    const handleClickNext = () => {
        setIndexActive((state) => {
            if (state >= DATA.length - 1) {
                return 0;
            }
            return state + 1;
        });
        handleAfterClick();
    };

    const handleClickPrev = () => {
        setIndexActive((state) => {
            if (state === 0) {
                return DATA.length - 1;
            }
            return state - 1;
        });
        handleAfterClick();
    };

    const handleAfterClick = () => {
        toggleIsRunning(false);
        if (TIME_OUT) {
            clearTimeout(TIME_OUT);
        }
        TIME_OUT = setTimeout(() => {
            toggleIsRunning(true);
        }, 1000);
    };

    const renderItem = (index: number) => {
        const isActive = indexActive === index;

        const item = DATA[index];
        if (!isActive) {
            return null;
        }

        return (
            <div
                className={`nc-SectionHero2Item nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
                key={index}
            >

                {/* BG */}
                <div className="absolute inset-0" style={{ backgroundColor: COLORS?.sliderBg }}>

                </div>

                <div className="sm:mb-10">
                    <div className="w-full relative">
                        <img
                            className="w-full h-[580px] xs:h-[500px] object-cover nc-SectionHero2Item__image"
                            src={item.image}
                            alt={item.heading}
                        />

                        <div
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:w-full"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                        >
                            <div className="lg:px-24 lg:py-12 md:p-6 sm:p-3 xs:px-4 xs:py-10">
                                <h1
                                    style={{ color: COLORS?.headingCaroussel }}
                                    className="font-julius font-[600] uppercase lg:text-[35px] sm:mb-3 md:text-[23px] xs:text-[32px] sm:text-[26px] sm:leading-[30px] xs:leading-[50px] text-center lg:leading-[50px]"
                                >
                                    {item?.heading}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return <>{DATA.map((_, index) => renderItem(index))}</>;
};

export default SectionHero;
