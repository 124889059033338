import React, { useEffect, useState } from 'react';
import { getAxeptio } from 'actions/integrations';

const AxeptioScript = () => {
    const [axeptioScript, setAxeptioScript] = useState();

    const loadAxeptioScript = () => {
        // Check if axeptioScript is not empty before appending to the DOM
        if (axeptioScript) {
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = axeptioScript;
            document.head.appendChild(scriptElement);
        }
    };

    useEffect(() => {
        getAxeptio()
            .then((res) => {
                setAxeptioScript(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        loadAxeptioScript();
    }, [axeptioScript]);

    return null;
};

export default AxeptioScript;
