import React, { FC, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

export interface NcDropDownItem {
  id: string;
  name: string;
  icon?: string;
  href?: string;
  TrackID?: string;
}

export interface NcDropDownProps {
  className?: string;
  panelMenusClass?: string;
  iconClass?: string;
  data: NcDropDownItem[];
  renderTrigger?: () => JSX.Element;
  renderItem?: (item: NcDropDownItem) => JSX.Element;
  title?: string;
  onClick: (item: NcDropDownItem) => void;
}

const NcDropDown: FC<NcDropDownProps> = ({
  className = ``,
  iconClass = "w-4 h-4 sm:h-5 sm:w-5",
  panelMenusClass = "origin-top-right",
  title = "More",
  renderTrigger,
  renderItem,
  data,
  onClick,
}) => {
  return (
    <Menu as="div" className="relative z-[9999]">
      <Menu.Button className={className} title={title}>
        {renderTrigger && (
          renderTrigger()
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-[999] ${panelMenusClass} right-0 w-56 mt-2 bg-white dark:bg-neutral-900 rounded-2xl divide-y divide-neutral-100 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-[9999]`}
        >
          <div className="px-1 py-3 text-sm text-neutral-6000 dark:text-neutral-300 z-[999]">
            {data.map((item, key) => (
              console.log(item),
              <Menu.Item
                as={"div"}
                key={item.id}
                data-menu-item-id={item.id}
                onClick={() => onClick(item)}
              >
                {() =>
                  renderItem && typeof renderItem(item) !== "undefined" ? (
                    renderItem(item)
                  ) : !!item.href ? (
                    <Link
                      to={item.href}
                      className={
                        "flex items-center rounded-md w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate "
                      }
                    >
                      {!!item.icon && (
                        <i className={`${item.icon} mr-1 w-7 text-base`}></i>
                      )}
                      <span className="truncate">{item.name}</span>
                    </Link>
                  ) : (
                    <button
                      onClick={() => window.open(
                        `https://gls-group.com/FR/fr/suivi-colis?match=` + item?.TrackID,
                        "_blank"
                      )}
                      className={
                        "flex items-center rounded-md w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate "
                      }
                    >
                      {!!item.icon && (
                        <i className={`${item.icon} mr-1 w-7 text-base`}></i>
                      )}
                      <span className="truncate">Colis N° {key + 1} →</span>
                    </button>
                  )
                }
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NcDropDown;
