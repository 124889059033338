import { getAllProductsInWishlist } from "actions/wishlist";
import ProductCard from "components/ProductCard";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AccountSavelists = () => {
  const [productsWishlist, setProductsWishlist]: any = useState([])
  const dispatch = useDispatch();
  const [refreshCounter, setRefreshCounter]: any = useState(0)

  useEffect(() => {
    const result = async () => {
      await dispatch(getAllProductsInWishlist()).then((res: any) => {
        setProductsWishlist(res)
      })
    };

    result();
  }, [refreshCounter]);

  return (
    <div>
      <Helmet>
        <title>Mes favoris | Conserverie Bertin</title>
      </Helmet>

      <div className={`container py-14`}>
        <div className={`flow-root`}>
          <div className="flex font-semibold justify-center flex-col lg:px-28">

            <div className="flex flex-row gap-1 font-[500] text-[13px]">
              <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
              <span>{">"}</span>
              <a href="">Mes favoris</a>
            </div>

            <div className="mt-16 mb-16">
              <div className="flex w-full mb-8">
                <div className="text-2xl sm:text-3xl font-semibold flex flex-row">
                  <Link to="/customer/overview" className="w-10 h-7 relative top-[.1rem] flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                    <span className="text-[20px] font-[500]">{"<"}</span>
                  </Link>
                  <h1 className="ml-4">Mes favoris</h1>
                </div>
              </div>
              <div>
                <div className="mt-2 md:mt-0 md:pl-16 space-y-6 items-center">
                  {productsWishlist &&
                    <div className="">
                      <div className="flex-1 grid sm:grid-cols-3 xl:grid-cols-3 gap-x-8 gap-y-10">

                        {Object.keys(productsWishlist).length !== 0 &&
                          <>
                            {Object.keys(productsWishlist).map((it: any, index: number) => (
                              <ProductCard refreshFnc={() => setRefreshCounter(refreshCounter + 1)} data={productsWishlist[it]} key={index} />
                            ))}
                          </>
                        }
                      </div>

                      {Object.keys(productsWishlist).length === 0 &&
                        <div className="text-center">
                          Aucun produit en favoris
                        </div>
                      }

                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountSavelists;
