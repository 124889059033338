import { USER_INFO_SUCCESS, USER_INFO_FAIL, SUCCESS_ADD_SHIPPING_ADDRESS } from '../../actions/types'

const defaultState = {}

export default (state = defaultState, action: any) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case USER_INFO_SUCCESS:
            return { ...payload };
        case SUCCESS_ADD_SHIPPING_ADDRESS:
            return { ...state, ...payload }; 
        case USER_INFO_FAIL:
            return { ...state, error: error };
        default:
            return state
    }
}