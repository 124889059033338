import { Dialog, Transition } from "@headlessui/react";
import { ClockIcon, NoSymbolIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { addProductInCart, addProductToAnonymousCart, createAnonymousCart } from "actions/cart";
import { getProductBySlug } from "actions/products";
import { addProductInWishlist, removeProductInWishlist } from "actions/wishlist";
import { IMG_LINK } from "config/key";
import { PRODUCTS } from "data/data";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import BagIcon from "./BagIcon";
import IconDiscount from "./IconDiscount";
import NcInputNumber from "./NcInputNumber";
import Prices from "./Prices";
import ReviewItem from "./ReviewItem";
import ImageViewer from 'react-simple-image-viewer';
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";

export interface ModalQuickViewProps {
  className?: string;
  show: boolean;
  onCloseModalQuickView: () => void;
  data: any
}

const ModalQuickView: FC<ModalQuickViewProps> = ({
  className = "",
  show,
  onCloseModalQuickView,
  data
}) => {
  const { status } = PRODUCTS[0];
  const [variantsObj, setVariantsObj] = useState<any>({});
  const [choosenProp, setchoosenProp] = useState<any>({});
  const [availableVar, setAvailableVar] = useState<any>([]);
  const _ = require('lodash');
  const [defaultVariant, setDefaultVariant] = useState<any>([]);
  const priceVariant: any = []
  const dispatch = useDispatch();
  const { wishlist }: any = useSelector(state => state);
  const [quantity, setQuantity] = useState<number>(1);
  const { auth }: any = useSelector(state => state)
  const { cart }: any = useSelector(state => state);
  let history = useHistory();
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [listImages, setListImages]: any = useState([]);
  const [currentImage, setCurrentImage] = useState(0);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  useEffect(() => {
    if (data?.Variants) {
      fillVariantObj(data.Variants);

      var defaultVariant = data.Variants.find((item: any) => item._id == data.defaultVariantId);
      setDefaultVariant(defaultVariant);

      setchoosenProp(defaultVariant?.properties)
    }

  }, [data]);

  const fillVariantObj = (variants: any) => {
    let variantsObj: any = {};


    variants.forEach((element: { _id: string | number; properties: any; }) => {
      variantsObj[element._id] = element.properties
    });

    setVariantsObj(variantsObj)
  }

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    let availableVar = []
    for (const variantsObjKey in variantsObj) {

      const variantsObjElement = variantsObj[variantsObjKey];
      let flag = true

      for (const choosenPropKey in choosenProp) {
        const choosenPropElement = choosenProp[choosenPropKey];

        if (variantsObjElement[choosenPropKey] != choosenPropElement) {
          flag = false
        }
      }

      if (flag) { availableVar.push(variantsObjKey) }
    }

    setAvailableVar(availableVar)

  }, [choosenProp])

  useEffect(() => {
    const img = ['image2Id', 'image3Id', 'image4Id', 'image5Id'];

    if (defaultVariant?.images) {
      let tempArr: any = []
      Object.keys(defaultVariant?.images).map((it: any) => {
        for (let index = 0; index < img.length; index++) {
          if (it === img[index]) {
            tempArr.push(IMG_LINK + defaultVariant?.images[it])
          }
        }
      })
      setListImages(tempArr)
    }
  }, [defaultVariant])

  useEffect(() => {
    if (data) {

      let test = data?.Variants?.map((it: any) => {
        if (_.isEqual(it.properties, choosenProp) == true) {
          return it
        }
      })

      const results = test?.filter((element: any) => {
        return element !== undefined;
      });

      if (results)
        setDefaultVariant(results[0])
    }

  }, [choosenProp])

  const handlePushVariant = (variant: any, key: string) => {
    if (choosenProp[key] && choosenProp[key] == variant) {
      let newChoosenProp = choosenProp
      setchoosenProp({ ...newChoosenProp })
    } else {
      setchoosenProp({ ...choosenProp, [key]: variant })
    }
  }

  const testFnc = (propKey: any, propValue: any) => {

    if (choosenProp[propKey] === propValue) {
      return "bg-[#ff9900] text-[white]"
    }

    return 'bg-[transparent] text-[black]'
  }

  const renderVariants = () => {

    if (data?.Variants && Array.isArray(data.Variants) && data.Variants.length > 0) {
      let arr: any = [];
      let propArr: any = {};

      data.Variants.map((variant: any) => {
        priceVariant.push(variant.price);

        if (variant?.properties) {
          Object.keys(variant.properties).map((prop) => {
            if (!arr.includes(prop))
              arr.push(prop)
          })
        }
      })

      arr.map((propertieName: string) => {
        propArr[propertieName] = []
      })

      arr.map((propertieName: string) => {
        data.Variants.map((variant: any) => {
          Object.keys(variant.properties).map((it) => {
            if (propertieName === it) {
              propArr[it].push(variant.properties[it])
            }
          })
        })
      })

      for (const propertie in propArr) {
        let uniqueChars = propArr[propertie].filter((element: any, index: any) => {
          return propArr[propertie].indexOf(element) === index;
        });

        propArr[propertie] = uniqueChars
      }

      return (
        <>
          {Object.keys(propArr).map((key, index) => (
            <div key={index} className="mt-3 mb-3">
              <div className="mr-2 text-[14px] capitalize mt-[.3rem] font-[500]">{key}:</div>
              <div className="flex flex-row">
                {Object.values(propArr[key]).map((it: any, index: number) => (
                  <div onClick={() => handlePushVariant(it, key)} key={index} className={`cursor-pointer mt-3 relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                  text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 px-6 mr-2 ${testFnc(key, it)} `}>{it}</div>
                ))}
              </div>
            </div>
          ))}
        </>
      )
    }
  }

  useEffect(() => {

    const findDefaultVariant = () => {
      const matchingVariant = data?.Variants?.find((variant: any) => {
        for (const key in choosenProp) {
          if (choosenProp[key] !== variant.properties[key]) {
            return false;
          }
        }
        return true;
      });

      if (matchingVariant) {
        setDefaultVariant(matchingVariant);
      }
    };

    findDefaultVariant();
  }, [choosenProp, data.Variants]);

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            Ajouter au panier!
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify()}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderPictures = () => {
    if (listImages) {
      return (
        <>
          {Object.keys(listImages).map((img: any, index: number) => (
            <div className="mr-4" key={index}>
              {img !== 'image1Id' &&
                <img
                  onClick={() => openImageViewer(index)}
                  className="rounded object-cover w-[115px] mb-3 cursor-pointer border-[1px] border-[#ff9900]"
                  src={listImages[index]}
                />
              }
            </div>
          ))}

        </>
      )
    }
  }


  const renderProductCartOnNotify = () => {
    return (
      <div className="flex ">
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          {data.images?.image1Id
            ?
            <img
              src={IMG_LINK + data.images?.image1Id}
              className="w-full h-full rounded object-cover"
              alt="product detail 1"
            />
            :
            <div
              className={`${className} flex items-center h-full w-full justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
              <div className="h-2/4 w-full h-full max-w-[50%]">
                <PlaceIcon />
              </div>
            </div>
          }
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{data.name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  {choosenProp &&
                    <>
                      {Object.keys(choosenProp).map((variant: any, index: number) => (
                        <div key={index}><span className="capitalize">{variant}</span> {choosenProp[variant]}</div>
                      ))}
                    </>
                  }

                  {/* <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span> */}
                </p>
              </div>
              <Prices price={defaultVariant.price} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">Quantité: x{quantity}</p>

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={(e) => {
                  e.preventDefault();
                  history.push("../cart")
                }}
              >
                Voir panier
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleAddProductWishlist = async (itemId: string) => {
    if (!wishlist.hasOwnProperty(data._id)) {
      await dispatch(addProductInWishlist(itemId));
    } else {
      await dispatch(removeProductInWishlist(itemId));
    }
  }

  const handleAddProductInCart = async () => {
    notifyAddTocart();

    if (data.simpleItem !== true) {
      const itemId = _.findKey(variantsObj, choosenProp);

      if (!auth?.token) {
        if (Object.keys(cart).length === 0) {
          await dispatch(createAnonymousCart()).then(async (res: any) => {
            await dispatch(addProductToAnonymousCart(res._id, itemId, quantity));
          })
        } else {
          await dispatch(addProductToAnonymousCart(cart._id, itemId, quantity));
        }
      } else {
        await dispatch(addProductInCart(itemId, quantity));
      }

    } else {
      if (!auth?.token) {
        if (Object.keys(cart).length === 0) {
          await dispatch(createAnonymousCart()).then(async (res: any) => {
            await dispatch(addProductToAnonymousCart(res._id, Object.keys(variantsObj)[0], quantity));
          })
        } else {
          await dispatch(addProductToAnonymousCart(cart._id, Object.keys(variantsObj)[0], quantity));
        }
      } else {
        await dispatch(addProductInCart(Object.keys(variantsObj)[0], quantity))
      }
    }
  }


  const renderSectionContent = () => {

    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {data.name}
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}


            {defaultVariant?.rebatedPrice
              ? <>
                <Prices
                  contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold"
                  price={defaultVariant.rebatedPrice}
                />

                <Prices
                  className="text-[red]"
                  contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold line-through text-red-500"
                  price={defaultVariant.price}
                />
              </>

              : <Prices
                contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold"
                price={defaultVariant.price}
              />
            }


          </div>
        </div>

        <div>
          <h3 className="text-[#7B768D] text-[16px]">
            {data?.shortDescription}
          </h3>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        <div className="">{renderVariants()}</div>

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-full">
            <NcInputNumber
              defaultValue={quantity}
              onChange={setQuantity}
            />
          </div>

          <ButtonPrimary
            onClick={() => { handleAddProductInCart() }}
            className={`${defaultVariant.quantity <= 0 ? 'cursor-not-allowed' : 'cursor-pointer'} flex-1 flex-shrink-0`}
            disabled={defaultVariant.quantity <= 0}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Ajouter au panier</span>
          </ButtonPrimary>

        </div>

        <hr className="2xl:!my-10 border-slate-200 dark:border-slate-700 xs:mt-6 xs:mb-6"></hr>

        <AccordionInfo defaultOpen={false} data={data} />

      </div>
    );
  };

  return (
    <>

      <Transition appear show={show} as={Fragment}>

        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={onCloseModalQuickView}
        >

          {isViewerOpen && (
            <ImageViewer
              src={listImages}
              currentIndex={currentImage}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
              disableScroll={true}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
                zIndex: 999
              }}
            />
          )}

          <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
                <div
                  className="flex-1 flex overflow-hidden max-h-full w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                >
                  <span className="absolute right-3 top-3 z-50">
                    <ButtonClose onClick={onCloseModalQuickView} />
                  </span>


                  <div className="flex-1 overflow-y-auto rounded-xl hiddenScrollbar">
                    <div className={`nc-ProductDetailPage ${className}`}>
                      {/* MAIn */}
                      <main className="p-10 mt-8">
                        <div className="lg:flex">
                          {/* CONTENT */}



                          <div className="w-full lg:w-[55%] ">
                            {/* HEADING */}

                            <div className="relative">

                              <div className="aspect-w-16 aspect-h-14">

                                {defaultVariant?.images?.image1Id
                                  ? <img
                                    src={IMG_LINK + defaultVariant?.images?.image1Id}
                                    className="w-full rounded object-cover"
                                    alt="product detail 1"
                                  />
                                  : <div className="w-full h-full object-cover rounded"
                                  >
                                    <div className=" flex items-center justify-center h-full bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                                      <div className="">
                                        <PlaceIcon />
                                      </div></div></div>
                                }


                                {auth.token !== null &&
                                  <>
                                    {auth.token !== undefined &&

                                      <div onClick={() => handleAddProductWishlist(data?._id)} className="flex justify-end p-2">
                                        <button className={`w-9 h-9 flex items-center justify-center rounded-full bg-white dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ${className}`}>
                                          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                                            <path
                                              d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                                              stroke={wishlist.hasOwnProperty(data._id) ? 'red' : "currentColor"}
                                              fill={wishlist.hasOwnProperty(data._id) ? 'red' : "white"}
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    }
                                  </>
                                }
                              </div>
                              {/* META FAVORITES */}

                              <div className="flex relative top[-250px] justify-center lg:justify-start xl:justify-start md:justify-start">
                                {renderPictures()}
                              </div>
                            </div>
                          </div>

                          {/* SIDEBAR */}
                          <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                            {renderSectionContent()}
                          </div>

                        </div>
                      </main>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalQuickView;
