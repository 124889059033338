import { Helmet } from "react-helmet";

const MentionsLegales = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Mentions légales | Conserverie Bertin</title>
            </Helmet>


            <div className="flex justify-center py-10">
                <div className="max-w-5xl p-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-juliu leading-[60px] text-[45px] mb-4">Mentions légales et politique de confidentialité</h1>
                    </div>

                    <div className="max-w-7xl mx-auto mt-14">
                        <h1 className="text-3xl font-bold mb-4">Identité</h1>
                        <div className="space-y-4">
                            <div>
                                <span className="font-bold">Nom du site web :</span> conserverie-bertin.fr
                            </div>
                            <div>
                                <span className="font-bold">Adresse :</span> <a href="https://conserverie-bertin.fr">https://conserverie-bertin.fr</a>
                            </div>
                            <div>
                                <span className="font-bold">Propriétaire :</span> Pêcherie de la Seudre Atlantique, SASU
                            </div>
                            <div>
                                <span className="font-bold">Responsable de publication :</span> Marine Bertin
                            </div>
                            <div>
                                <span className="font-bold">Conception et réalisation :</span> Milango
                            </div>
                            <div>
                                <span className="font-bold">Hébergement :</span> Milango
                            </div>
                        </div>

                        <h2 className="text-2xl font-bold mt-8 mb-4">Personne morale</h2>
                        <div className="space-y-4">
                            <div>
                                <span className="font-bold">Pêcherie de la Seudre Atlantique, SASU</span>
                            </div>
                            <div>
                                Immatriculé au RCS de La Rochelle, sous le numéro 384 683 967
                            </div>
                            <div>
                                1 bis rue Pierre Loti 17390 La Tremblade
                            </div>
                            <div>
                                <span className="font-bold">Email :</span> conserveriebertin@gmail.com
                            </div>
                            <div>
                                <span className="font-bold">Téléphone :</span> 0564580005
                            </div>
                        </div>

                        <h2 className="text-2xl font-bold mt-8 mb-4">Conditions d'utilisation</h2>
                        <p>
                            L'utilisation du présent site implique l'acceptation pleine et entière des conditions générales d'utilisation décrites ci-après.
                            Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment.
                        </p>

                        <h2 className="text-2xl font-bold mt-8 mb-4">Informations</h2>
                        <p>
                            Les informations et documents du site sont présentés à titre indicatif, n'ont pas de caractère exhaustif, et ne peuvent engager la responsabilité du propriétaire du site.
                            Le propriétaire du site ne peut être tenu responsable des dommages directs et indirects consécutifs à l'accès au site.
                        </p>

                        <h2 className="text-2xl font-bold mt-8 mb-4">Interactivité</h2>
                        <p>
                            Les utilisateurs du site peuvent y déposer du contenu, apparaissant sur le site dans des espaces dédiés (notamment via les commentaires).
                            Le contenu déposé reste sous la responsabilité de leurs auteurs, qui en assument pleinement l'entière responsabilité juridique.
                            Le propriétaire du site se réserve néanmoins le droit de retirer sans préavis et sans justification tout contenu dépos
                        </p></div>

                </div>
            </div>


        </div>
    );
}

export default MentionsLegales;
