export const COLORS = {
    preHeader: "#F7ECDB",
    preHeaderText: "#234336",

    // NAVBAR 
    navbar: 'black',
    navbarText: '#FFFFFF',
    navbarTextHover: '#234336',
    navbarArrow: '#FFFFFF',


    // SLIDER
    sliderText: 'red',
    sliderBg: 'white',
    sliderBgText: "black",

    // FOOTER
    footer: '#eee',
    footerText: '#7c7d7d',


    // BUTTON
    buttonPrimary: '#f90',
    buttonSecondary: '#f90',
    buttonThird: '#f90',
    filterBtn: '#6E5D4A',

    // INPUT
    searchInputBorder: 'white',

    heading: '#234336',
    text: '#F90',
    headingCaroussel: "white",
    paragraphCaroussel: "#234336",
    placeholderSearchInput: 'white',
    backgroundHowItWork: '',
    loadingSpinner: '',
}

export const BORDERS = {
    buttonVariant: '5px',
    input: '14px',
    buttonPrimary: '3px',
    buttonSecondary: '3px',
    buttonThird: '3px',
    searchInput: '999px',
    inputQuantityNumber: '0px',
    input: ''
}
