import { BrowserRouter, Switch, Route, Redirect, useHistory } from "react-router-dom";

import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import SiteHeader from "containers/SiteHeader";
import PageSearch from "containers/PageSearch";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutInformationPage from "containers/PageCheckout/CheckoutInformationPage";
import PageCollection2 from "containers/PageCollection";
import PageForgotPswd from "containers/PageForgotPswd/PageForgotPswd";
import ShippingAddress from "containers/PageCheckout/ShippingAddress";
import AddShippingAddress from "containers/PageCheckout/AddShippingAddress";
import EditShippingAddress from "containers/PageCheckout/EditShippingAddress";
import CheckoutDelivery from "containers/PageCheckout/CheckoutDelivery";
import CheckoutPayment from "containers/PageCheckout/CheckoutPayment";
import Footer from "shared/Footer/Footer";
import OnChangeRoute from "./OnChangeRoute";

import ConserverieBertin from "containers/Pages/ConserverieBertin";
import NotreGammeDeSoupe from "containers/Pages/NotreGammeDeSoupe";

import { Page } from "./types";
import ProductCategory from "containers/ProductCategory";
import AuFilDesJours from "containers/Pages/AuFilDesJours";
import Contact from "containers/PageContact/Contact";
import Cgv from "containers/PageCGV/Cgv";
import MentionsLegales from "containers/PageMentionsLegales/MentionsLegales";
import RecetteAutomne from "containers/Pages/RecetteAutomne";
import RecetteEte from "containers/Pages/RecetteEte";
import AujourdhuiCestLangoustine from "containers/Pages/AujourdhuiCestLangoustine";
import { useEffect } from "react";
import Overview from "containers/Customer/Overview";
import ConfirmPayment from "containers/PageCheckout/ConfirmPayment";

export const auth = (Component: any) => {
  return (props: any) => {
    const refreshToken = localStorage.getItem("token");
    const history = useHistory();

    useEffect(() => {
      if (!refreshToken) {
        history.push("/login");
      }
    }, [refreshToken, history]);

    return refreshToken ? <Component {...props} /> : <Redirect to="/login" />;
  };
};

export const notAuth = (Component: any) => {
  return (props: any) => {
    const refreshToken = localStorage.getItem("token");
    const history = useHistory();

    useEffect(() => {
      if (refreshToken) {
        history.push("/");
      }
    }, [refreshToken, history]);

    return refreshToken ? <Redirect to="/" /> : <Component {...props} />;
  };
};

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  //
  { path: "/home-header-2", exact: true, component: PageHome },

  { path: "/product-detail/:slug", component: ProductDetailPage },

  { path: "/customer/overview", component: Overview },



  { path: "/pages/conserverie-bertin", component: ConserverieBertin },
  { path: "/pages/notre-gamme-de-soupe", component: NotreGammeDeSoupe },
  { path: "/pages/au-fil-des-jours", component: AuFilDesJours },
  { path: "/pages/recette-automne-2021", component: RecetteAutomne },
  { path: "/pages/recette-ete-2021", component: RecetteEte },
  { path: "/pages/aujourdhui-cest-langoustine", component: AujourdhuiCestLangoustine },

  // CONTACT
  { path: "/contact", component: Contact },


  // CGV
  { path: "/cgv", component: Cgv },
  { path: "/mentions-legales", component: MentionsLegales },


  //
  { path: "/page-collection", component: PageCollection2 },
  { path: "/search", component: PageSearch },
  { path: "/products/", component: ProductCategory },
  //
  { path: "/account", component: auth(AccountPage) },
  { path: "/account-savelists", component: auth(AccountSavelists) },
  { path: "/account-change-password", component: auth(AccountPass) },
  { path: "/shipping-address", component: auth(ShippingAddress) },
  { path: "/add-shipping-address", component: auth(AddShippingAddress) },
  { path: "/edit-shipping-address/:id", component: auth(EditShippingAddress) },
  { path: "/account-my-order", component: auth(AccountOrder) },
  //
  { path: "/cart", component: CartPage },
  { path: "/checkout/information", component: CheckoutInformationPage },
  { path: "/checkout/delivery", component: CheckoutDelivery },
  { path: "/checkout/payment", component: CheckoutPayment },
  { path: "/checkout/confirm", component: ConfirmPayment },
  
  //
  { path: "/signup", component: notAuth(PageSignUp) },
  { path: "/login", component: notAuth(PageLogin) },
  { path: "/forgot-pass/:id", component: PageForgotPswd },
  { path: "/forgot-pass", component: PageForgotPswd },
];

const Routes = () => {
  return (
    <BrowserRouter basename="/">
      <OnChangeRoute />
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}

            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
