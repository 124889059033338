import { GET_LANGUAGE, SET_LANGUAGE } from '../../actions/types'

const defaultState = {}

export default (state = defaultState, action: any) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case SET_LANGUAGE:
            return { lang: payload }
        default:
            return state
    }
}