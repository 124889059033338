import { apiClient } from './apiClient'

function addProductInWishlist(idProduct: string) {
    return apiClient().post('/wishlist/' + idProduct)
}

function removeProductInWishlist(idProduct: string) {
    return apiClient().delete('/wishlist/' + idProduct)
}

function getAllProductsInWishlist() {
    return apiClient().get('/wishlist')
}

export default {
    addProductInWishlist,
    removeProductInWishlist,
    getAllProductsInWishlist
}
