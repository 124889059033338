
import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";

import Rein from "images/rein.png";
import Img1 from "images/im2.png";
import Img2 from "images/im3.png";
import Img3 from "images/im4.png";
import Img4 from "images/im1.png";

import JosetteAvatar from "images/josette.png";
import BrunoAvatar from "images/bruno.png";

import MarineAvatar from "images/marine.png";
import HugoAvatar from "images/hugo.png";
import JackyAvatar from "images/jacky.png";
import JeanLucAvatar from "images/jean-luc.png";


import Img5 from "images/img13.jpeg";

const ConserverieBertin = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Conserverie Bertin</title>
            </Helmet>

            {/* CAROUSSEL  */}
            <SectionHero />
            {/* CAROUSSEL  */}

            {/* OUR HISTORY  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col mt-10 mb-10">

                            <div className="flex justify-center mb-14 mt-10">
                                <h1 className="uppercase text-[45px] text-[#777] text-center font-julius font-[600]  leading-[55px]">Notre histoire</h1>
                            </div>

                            <div className="flex flex-col">

                                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 gap-6">
                                    <div>
                                        <img className="w-full h-full" src={Img1} alt="Image 10" />
                                    </div>
                                    <div className="col-span-2 text-[14px] leading-[25px] font-[500]">
                                        <h1 className="font-bold mb-4 text-[21px] text-[#777]">Chez les Bertin, le poisson, c’est l’ADN de la famille. Cinq générations dans le domaine piscicole, de la pêche à l’ostréiculture.<br />Tout commence en 1969...</h1>
                                    </div>

                                    <div>
                                        <img className="w-full h-full" src={Img2} alt="Image 10" />
                                    </div>
                                    <div className="col-span-2 text-[14px] leading-[25px] font-[500]">
                                        <p>Les parents de Bruno inaugurent leur poissonnerie à la Palmyre. Pour cet événement, Josette prépare une soupe de poissons. Elle a tellement de succès que certains lui demandent d’en faire à la vente. C’est ainsi que né Le Velouté Trembladais. Produit phare de la maison, il est d’abord commercialisé en vrac. A cette époque, les clients viennent chercher leur soupe avec leur pot à lait. Avec l’augmentation de la demande, une mise en bouteille est nécessaire.</p>
                                    </div>

                                    <div>
                                        <img className="w-full h-full" src={Img3} alt="Image 10" />
                                    </div>
                                    <div className="col-span-2 text-[14px] leading-[25px] font-[500]">
                                        <h1 className="font-bold mb-4 text-[21px] text-[#777]">Dès 16 ans, Bruno, le fils, se consacre à l’ostréiculture...</h1>
                                        <p>Pourtant il a plein d’idées pour cette soupe et pousse ses parents à en créer d’autres. A 18 ans, il leur demande de lui confier la partie confection des soupes. Sa mère lui apprend les gestes et les secrets de sa recette. Il invente rapidement une nouvelle soupe La Trembladaise, soupe de poissons et langoustines ainsi que La Cotinarde faite spécialement pour la Criée de la Cotinière.</p>
                                    </div>

                                    <div>
                                        <img className="w-full h-full" src={Img4} alt="Image 10" />
                                    </div>
                                    <div className="col-span-2 text-[14px] leading-[25px] font-[500]">
                                        <h1 className="font-bold mb-4 text-[21px] text-[#777]">Perpétuer la tradition familiale</h1>
                                        <p>En jouant la carte de la simplicité, de la qualité et de l’authenticité, la troisième génération fait son entrée. En 2014, Marine, la petite fille de la créatrice du Velouté Trembladais, intègre l’entreprise. Une belle collaboration naît entre Marine et son père. Son arrivée permet d’apporter une touche de modernité et une vue nouvelle sur l’ensemble de l’entreprise.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* OUR HISTORY  */}

            {/* OUR ROOT  */}
            <div className="bg-[#1a1819]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col mb-10">

                            <div className="flex justify-center mt-10 mb-14">
                                <h1 className="uppercase text-[45px] text-[white] font-julius font-[600]">Nos racines</h1>
                            </div>


                            <div className="flex grid lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-24 text-[white]">
                                <div className="text-center">
                                    <img src={JosetteAvatar} alt="Image 10" />
                                    <h1 className="font-julius text-[35px] mt-10">JOSETTE BERTIN</h1>
                                    <p className="text-[15px] leading-[30px] mt-6 font-normal">Créatrice de la première recette de soupe de poissons de la Conserverie BERTIN. Au vu du succès que rencontre sa recette de soupe de poissons, elle décide d’entreprendre une production beaucoup plus importante afin de la commercialiser. L’aventure de la Conserverie BERTIN commence ici...</p>
                                </div>

                                <div className="text-center">
                                    <img src={BrunoAvatar} alt="Image 10" />
                                    <h1 className="font-julius text-[35px] mt-6">BRUNO BERTIN</h1>
                                    <p className="text-[15px] leading-[30px] mt-6 font-normal">Fils de la créatrice du Velouté Trembladais. Il reprend les rênes de l’entreprise en 1992 et la fait évoluer en créant deux nouvelles recettes. Il élargi également son réseau de distribution, ce qui permet à la Conserverie d’être reconnue dans toute la région. Il laisse sa place à ses enfants en septembre 2019.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* OUR ROOT  */}

            {/* OUR TEAMS  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col mt-10">

                            <div className="flex justify-center mb-14 mt-10">
                                <h1 className="uppercase text-[45px] text-[#777] font-julius leading-[55px] font-[600] text-center lg:whitespace-nowrap md:whitespace-nowrap xl:whitespace-nowrap">& l'équipe de la conserverie bertin</h1>
                            </div>


                            <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-4 md:grid-cols-2 gap-8 w-full">
                                <div>
                                    <img src={MarineAvatar} className="w-full" />
                                    <h1 className="text-[#777] text-[24px] font-julius mt-4 mb-4">MARINE BERTIN</h1>
                                    <p className="text-[16px] leading-[27px] font-normal">Dans la société depuis 2014 Gérante depuis septembre 2019, je gère la partie administrative et commerciale de l’entreprise ainsi que la relation clientèle. Je m’occupe de la communication de l’entreprise ainsi que de la préparation de vos commandes. Avec mon frère Hugo, nous souhaitons mettre notre pierre à l’édifice de la Conserverie BERTIN avec de nouveaux produits.</p>
                                </div>

                                <div>
                                    <img src={HugoAvatar} className="w-full" />
                                    <h1 className="text-[#777] text-[24px] font-julius mt-4 mb-4">HUGO BERTIN</h1>
                                    <p className="text-[16px] leading-[27px] font-normal">Dans la société depuis 2018 Gérant depuis septembre 2019, je suis également responsable hygiène et qualité. Je fais partie de l’équipe de production. Mon travail consiste à contrôler la qualité de chaque étape de la production ainsi que l’hygiène dans toute l’entreprise afin de vous offrir des produits d’exception. Avec Marine, nous avons de plusieurs projets afin de développer la Conserverie BERTIN. De nombreuses surprises vont arriver !</p>
                                </div>

                                <div>
                                    <img src={JackyAvatar} className="w-full" />
                                    <h1 className="text-[#777] text-[24px] font-julius mt-4 mb-4">JACKY HUOT</h1>
                                    <p className="text-[16px] leading-[27px] font-normal">Dans la société depuis 1999 Je suis responsable de production et je m'applique à organiser les productions de soupes journalières. Je fais partie de l’équipe de production. Tout au long de l'année, j'effectue les achats de matières premières.</p>
                                </div>

                                <div>
                                    <img src={JeanLucAvatar} className="w-full" />
                                    <h1 className="text-[#777] text-[24px] font-julius mt-4 mb-4">JEAN-LUC PISON</h1>
                                    <p className="text-[16px] leading-[27px] font-normal">Dans la société depuis 2001 Je suis préparateur des produits, je m’occupe de nettoyer tout le poisson reçu. Je fais partie de l’équipe de production et livre également les commandes sur la presqu’ile d’Arvert.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* OUR TEAMS  */}


            {/* OUR PRODUCTION  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col lg:mt-20 mt-10 mb-10">

                            <div className="flex justify-center mb-14">
                                <h1 className="uppercase text-[45px] text-[#777] text-center leading-[55px] font-julius font-[600]">NOTRE PRODUCTION</h1>
                            </div>


                            <div className="grid lg:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 grid-cols-1 gap-6">
                                <div>
                                    <img src={Img5} className="w-full h-full" />
                                </div>

                                <div className="flex flex-col lg:mt-8">
                                    <div className="items-center">
                                        <h1 className="text-[21px] text-[#777] leading-[25px]">La recette d’une bonne soupe de poissons tient en quelques principes simples et de bon sens : du poisson de qualité, un mélange harmonieux d’épices, du savoir-faire.</h1>
                                        <p className="text-[16px] font-normal leading-[24px] mt-6">Nous préparons un fumet de légumes avec des carottes, poireaux, pommes de terre, de l’oignon, du vin blanc, du beurre de Charente, le tout brassé à chaud avec de la farine. Nous ajoutons ensuite un mélange d’épices au dosage secret avec du poivre, du piment doux et fort, du thym, du romarin, du clou de girofle... Vient alors le moment où l’on adjoint le poisson, ou plutôt les poissons. Ils sont achetés presque exclusivement à la Criée de la Cotinière, sur l’île d’Oléron. On y trouve du congre, du merlan, du grondin, du mulet, de la rascasse… Poissons, épices, fumet de légumes subissent une première cuisson. Nous versons ensuite de la sauce tomate, on brasse, on broie, pour obtenir un mélange le plus fin possible, avant une seconde cuisson. Au final : zéro déchet. Les bocaux sont ensuite remplis à l’entonnoir, avant d’être capsulés. Ils passent une bonne heure dans un autoclave pour une séance de stérilisation qui garantit une durée de consommation de 3 ans.</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {/* OUR PRODUCTION  */}




            {/* BEFORE FOOTER  */}
            <div className="bg-[#1a1819]">
                <div className={`container`}>
                    <div className="py-10 flex justify-center">
                        <img src={Rein} />
                    </div>
                </div>
            </div>
            {/* BEFORE FOOTER  */}

        </div>
    );
}

export default ConserverieBertin;
