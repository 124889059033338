import { login, register } from "actions/auth";
import {
  getAnonymousCart,
  getProductInCart,
  mergeAnonymousCart,
  patchCart,
  patchCartAnonymousCart
} from "actions/cart";
import { getOrderPreviewInAnonymousCart } from "actions/orders";
import { addShippingAddress, editShippingAddress, getAllShippingAddress, getUserInfos, updateUserInfos } from "actions/user";
import { getAllProductsInWishlist } from "actions/wishlist";
import Label from "components/Label/Label";
import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { Product } from "data/data";
import { useEffect, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { Helmet } from "react-helmet";
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import { GOOGLE_MAP_API, IMG_LINK } from "../../config/key";
import { t } from "i18next";

const CheckoutInformationPage = () => {
  const location: any = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [collaspeOrderSummary, setCollaspeOrderSummary] = useState(false);
  const [cart, setCart]: any = useState<Array<Product>>([]);
  const { auth }: any = useSelector(state => state)
  const { user }: any = useSelector(state => state)
  const { cartLength }: any = useSelector((state: any) => state.cart)
  const [shippingAddress, setShippingAddress]: any = useState([]);
  const [billingAddress, setBillingAddress]: any = useState([]);
  const [addressBilling, setAddressBilling]: any = useState([])
  const [address, setAddress]: any = useState([])
  const cartAnonymous: any = useSelector(state => state)
  const [allShippingAddress, setAllShippingAddress]: any = useState([]);
  const [checkoutAsGuest, setCheckoutAsGuest]: any = useState(true);
  const [checkoutAsClient, setCheckoutAsClient]: any = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [phone, setPhone] = useState('')
  const [emailRegister, setEmailRegister] = useState('')
  const anonymousCart: any = useSelector(state => state);
  const [dataResponse, setDataResponse]: any = useState([]);
  const [discountCode, setDiscountCode]: any = useState();
  const [editShipping, setEditShipping]: any = useState(false);
  const [editBilling, setEditBilling]: any = useState(false);

  useEffect(() => {
    if (location?.pathname === "/checkout/information" && (!cartLength || cartLength === 0)) {
      history.push("/cart")
    }

    function handleWindowResize() {
      const isMobile = window.innerWidth <= 768; // Largeur considérée comme mobile (ajustez cette valeur selon vos besoins)
      setCollaspeOrderSummary(!isMobile);
    }

    handleWindowResize(); // Vérifier l'état initial lors du rendu initial

    window.addEventListener('resize', handleWindowResize); // Ajouter un écouteur d'événement pour détecter les changements de taille d'écran

    return () => {
      window.removeEventListener('resize', handleWindowResize); // Nettoyer l'écouteur d'événement lors du démontage du composant
    };
  }, []);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getProductInCart()).then((res: any) => {
        setCart(res);
      })

    } else {

      if (cartAnonymous?.cart?.cartLength !== 0 && cartAnonymous?.cart?._id !== undefined) {
        dispatch(getAnonymousCart(cartAnonymous?.cart?._id)).then((res: any) => {
          setCart(res);

          if (res?.cartLength !== 0) {
            getOrderPreviewInAnonymousCart(cartAnonymous?.cart?._id).then((data: any) => {
              // setOrderPreview(data)
            })
          }
        })
      }

    }
  }, [dataResponse])

  useEffect(() => {
    if (auth?.token) {
      const fetchData = async () => {
        const data = await getAllShippingAddress();
        setAllShippingAddress(data.shippingAddress);
      }

      fetchData();
    }
  }, [user?.shippingAddress])

  const handleAddDiscountCode = async () => {
    if (auth?.token) {
      const data = {
        discountCode: discountCode
      }

      await dispatch(patchCart(data)).then((res: any) => {
        setDataResponse(res);
      }).catch((err: any) => {
        NotificationManager.error(err?.response?.data?.error, '');
      })
    } else {

      const data = {
        discountCode: discountCode
      }

      await dispatch(patchCartAnonymousCart(cartAnonymous?.cart?._id, data)).then((res: any) => {
        setDataResponse(res);
      }).catch((err: any) => {
        NotificationManager.error(err?.response?.data?.error, '');
      })
    }
  }

  const handleDisplayCheckoutAsGuest = () => {
    setCheckoutAsGuest(true);
    setCheckoutAsClient(false);
  }

  const handleDisplayCheckoutAsClient = () => {
    setCheckoutAsClient(true);
    setCheckoutAsGuest(false);
  }

  useEffect(() => {
    if (shippingAddress) {
      let result: any = {}


      shippingAddress?.address_components?.map((it: any) => {
        if (it.types[0] === "country") {
          result['country'] = it.long_name
        }
        if (it.types[0] == "locality") {
          result['city'] = it.long_name
        }
        if (it.types[0] == "postal_code") {
          result['postalCode'] = it.long_name
        }


        result['address'] = shippingAddress?.formatted_address?.split(',')[0]
        result['defaut'] = true;

        if (shippingAddress?.geometry) {
          result['lat'] = shippingAddress?.geometry?.location.lat();
          result['long'] = shippingAddress?.geometry?.location.lng();
        }

      })
      setAddress(result)
    }
  }, [shippingAddress])

  useEffect(() => {
    if (billingAddress) {
      let result: any = {}

      billingAddress?.address_components?.map((it: any) => {
        if (it.types[0] === "country") {
          result['country'] = it.long_name
        }
        if (it.types[0] == "locality") {
          result['city'] = it.long_name
        }
        if (it.types[0] == "postal_code") {
          result['postalCode'] = it.long_name
        }

        result['address'] = billingAddress?.formatted_address?.split(',')[0]
        result['defaut'] = true;

        if (billingAddress?.geometry) {
          result['lat'] = billingAddress?.geometry?.location.lat();
          result['long'] = billingAddress?.geometry?.location.lng();
        }

      })

      setAddressBilling(result)
    }
  }, [billingAddress])

  const renderProduct = () => {
    return (
      <>
        {cart?.item &&

          <>
            {Object.keys(cart?.item).map((item: any, index: number) => (

              <div
                key={index}
                className="relative flex py-8 sm:py-10 xl:py-5 first:pt-0 last:pb-0"
              >
                <div className="relative h-20 w-20 sm:w-40 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">

                  <div key={index}>
                    {cart?.item[item]?.itemRef?.images?.image1Id
                      ?
                      <>
                        <img
                          className="h-full w-full "
                          src={IMG_LINK + cart?.item[item]?.itemRef?.images?.image1Id}
                          alt={item.name}
                        />
                      </>
                      :
                      <div className="nc-NcImage flex aspect-w-11 aspect-h-12 w-full h-0">
                        <div className={`flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
                          <div className="h-2/4 max-w-[50%]">
                            <PlaceIcon />
                          </div>
                        </div>
                      </div>
                    }

                  </div>

                </div>

                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                  <div>
                    <div className="flex justify-between ">
                      <div className="flex-[1.5] ">
                        <h3 className="text-base font-semibold">
                          <Link to="/product-detail">{cart?.item[item]?.name}</Link>
                        </h3>
                        <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300">
                          <div>
                            {cart?.item[item]?.itemRef?.properties &&
                              <>
                                {Object.keys(cart?.item[item]?.itemRef?.properties).map((propertie: any, index: number) => (
                                  <div key={index}>
                                    <div><span className="capitalize">{propertie}</span>: {cart?.item[item]?.itemRef?.properties[propertie]}</div>
                                  </div>
                                ))}
                              </>
                            }

                          </div>
                        </div>

                        <div className="mt-1 flex justify-between w-full sm:hidden relative">
                          <span className="text-sm text-slate-600">Quantité: x{cart?.item[item]?.quantity}</span>

                          <div className="sm:block text-center relative">

                            {cart?.item[item]?.priceWithDiscount !== undefined && cart?.item[item]?.priceWithDiscount !== cart?.item[item]?.price ?
                              <>
                                <div className="flex flex-row">
                                  <div className="mr-1">
                                    <Prices
                                      key={index}
                                      contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                      price={cart?.item[item]?.priceWithDiscount}
                                    />
                                  </div>
                                  <div className="line-through text-[#e74c3c]">
                                    {cart?.item[item]?.price} €
                                  </div>
                                </div>
                              </>
                              :
                              <>
                                {cart?.item[item]?.rebatedPrice
                                  ? <div className="flex flex-row"><Prices
                                    key={index}
                                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                    price={cart?.item[item]?.rebatedPrice}
                                  /><Prices
                                      key={index}
                                      contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-red-500 line-through text-sm font-medium h-full"
                                      price={cart?.item[item]?.price}
                                    /></div>
                                  : <Prices
                                    key={index}
                                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                    price={cart?.item[item]?.price}
                                  />
                                }
                              </>
                            }

                          </div>
                        </div>
                      </div>



                      <div className="hidden sm:block text-center relative">
                        <NcInputNumber className="relative z-10" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        }
      </>

    );
  };

  const handleLogin = async (email: string, password: string): Promise<void> => {
    await dispatch(login(email, password)).then(async () => {
      await dispatch(getAllProductsInWishlist());
      await dispatch(getUserInfos());
      await dispatch(getProductInCart());

      if (anonymousCart) {
        await dispatch(mergeAnonymousCart(anonymousCart?.cart?._id));
      }

    }).catch((err: any) => {
      NotificationManager.error(err.response.data.error, '');
    })
  }

  const handleSignUp = async () => {
    let user = {
      name: name,
      lastname: lastname,
      email: emailRegister,
      password: password,
      phone: phone,
    }

    await dispatch(register(user)).then(async () => {
      if (anonymousCart) {
        await dispatch(mergeAnonymousCart(anonymousCart?.cart?._id));
      }
    })
      .catch((err: any) => {
        NotificationManager.error(err.response.data.error, '');
      })

  }

  const handleGoToNextStep = async () => {
    if (auth?.token && allShippingAddress?.length === 0 || allShippingAddress === undefined && auth?.user?.billingAddress === undefined || user?.billingAddress === undefined) {

      if (user?.billingAddress === undefined && Object.keys(address).length !== 0) {
        let user: any = {
          billingAddress: addressBilling,
        }

        await dispatch(updateUserInfos(user))
        await dispatch(addShippingAddress(address))
      } else if (Object.keys(address).length !== 0) {
        await dispatch(addShippingAddress(address))
      } else if (user?.billingAddress === undefined) {
        let user: any = {
          billingAddress: addressBilling,
        }

        await dispatch(updateUserInfos(user))
      }
    }

    if (user?.billingAddress && allShippingAddress?.length !== 0) {
      allShippingAddress.map((it: any, index: number) => {
        if (it.defaut === true) {
          history.push({ pathname: "./delivery", state: { postalCode: it?.postalCode, city: it?.city } })
        }
      })
    }

  }

  const handleDeleteDiscountCode = async () => {
    if (auth?.token) {

      const data = {
        discountCode: -1
      }

      await dispatch(patchCart(data)).then((res: any) => {
        setDataResponse(res)
      })
    } else {

      const data = {
        discountCode: -1
      }

      await dispatch(patchCartAnonymousCart(cartAnonymous?.cart?._id, data)).then((res: any) => {
        setDataResponse(res)
      })
    }
  }

  const handleShowShippingAddress = () => {
    setEditShipping(true);
  }

  const handleShowBillingAddress = () => {
    setEditBilling(true);
  }

  const handleEditShippingAddress = async (id: number) => {
    let res = {
      address: address.address,
      city: address.city,
      country: address.country,
      postalCode: address.postalCode,
      long: address.long,
      lat: address.lat,
      _id: Number(id),
      defaut: true,
    }

    await dispatch(editShippingAddress(res)).then((res: any) => {
      setEditShipping(false);
      NotificationManager.success("Votre adresse de livraison a bien été modifiée!", '')
    }).catch((err: any) => {
      NotificationManager.error(err.response.data.error, '');
    })
  }

  const handleEditBillingAddress = async () => {
    let user: any = {
      billingAddress: addressBilling,
    }

    await dispatch(updateUserInfos(user)).then((res: any) => {
      setEditBilling(false);
      NotificationManager.success("Votre adresse de facturation a bien été modifiée!", '')
    }).catch((err: any) => {
      NotificationManager.error(err.response.data.error, '');
    })

  }

  const handleCollapseOrderSummary = () => {
    setCollaspeOrderSummary(true)

    if (collaspeOrderSummary) {
      setCollaspeOrderSummary(false);
    }
  }

  return (
    <div className="nc-CartPage">
      <Helmet>
        <title>Tunnel d'achat | Conserverie Bertin</title>
      </Helmet>

      <main className="container py-16 lg:pb-28 lg:pt-20 ">
        <div className="">
          <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
            Tunnel d'achat
          </h2>
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <span className="underline">Informations</span>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            Livraison
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            Paiement
          </div>
        </div>

        <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12 hidden lg:block xl:block" />

        <div className="flex flex-col lg:flex-row w-full">
          <div className="w-full lg:w-[60%] xl:w-[55%] order-2 lg:order-none xl:order-none">

            <div className="w-full">

              {!auth.token &&
                <div>
                  <div className="flex justify-between">
                    <div>
                      <h1 className="font-[500] text-[19px]">Informations personnelles</h1>
                    </div>
                  </div>
                  <div className="mt-10 flex flex-row">
                    <div className="mt-3">
                      <span onClick={() => handleDisplayCheckoutAsGuest()} className="cursor-pointer">Créer un compte</span>
                      <span className="mr-4 ml-4">|</span>
                    </div>

                    <ButtonPrimary onClick={() => handleDisplayCheckoutAsClient()}>Connexion</ButtonPrimary>
                    <hr />
                  </div>

                  {checkoutAsGuest &&
                    <div className="mt-10 border-[1px] rounded p-6">

                      <div className="grid grid-cols-2 gap-6">
                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Prénom
                          </span>

                          <Input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            placeholder="John"
                            className="mt-1"
                          />
                        </label>
                        <label className="block">
                          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Nom
                          </span>

                          <Input
                            type="text"
                            placeholder="Doe"
                            className="mt-1"
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="grid grid-cols-1 mt-4">

                        <label className="block mb-4">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Numéro de téléphone
                          </span>
                          <span className="text-[red] ml-1">*</span>

                          <Input
                            onChange={(e) => setPhone(e.target.value)}
                            type="text"
                            placeholder="+33"
                            className="mt-1"
                          />
                        </label>


                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Adresse email
                          </span>
                          <span className="text-[red] ml-1">*</span>

                          <Input
                            onChange={(e) => setEmailRegister(e.target.value)}
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1"
                          />
                        </label>

                        <label className="block mt-4">
                          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Mot de passe
                          </span>
                          <Input
                            type="password"
                            className="mt-1"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={'**********'}
                          />
                          <span className="text-slate-500 text-xs italic">*Minimum 1 majuscule et 1 caractère spécial</span>
                        </label>
                        <ButtonPrimary className="mt-4" onClick={() => handleSignUp()}>S'inscrire</ButtonPrimary>
                      </div>
                    </div>
                  }



                  {checkoutAsClient &&
                    <>
                      <div className="mt-10 border-[1px] rounded p-6">

                        <label className="block">
                          <span className="text-neutral-800 dark:text-neutral-200">
                            Adresse email
                          </span>
                          <Input
                            type="email"
                            placeholder="example@example.com"
                            className="mt-1"
                            onChange={(e: any) => setEmail(e.target.value)}
                          />
                        </label>
                        <label className="block mt-4">
                          <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                            Mot de passe
                          </span>
                          <Input
                            type="password"
                            onChange={(e: any) => setPassword(e.target.value)}
                            className="mt-1"
                            placeholder="**********"
                          />
                          <div onClick={() => history.push({ pathname: '/forgot-pass', state: { email: email } })} className="flex justify-end mt-4 cursor-pointer hover:underline text-sm text-green-600">
                            Mot de passe oublié?
                          </div>
                        </label>
                        <ButtonPrimary className="mt-4 w-full" onClick={() => handleLogin(email, password)} type="submit">Se connecter</ButtonPrimary>
                      </div>
                    </>
                  }

                </div>
              }

              {auth.token &&
                <div className={`${!auth.token && "mt-8"}`}>
                  <h1 className="font-[500] text-[19px]">Adresse d’expédition</h1>

                  {!editShipping &&

                    <>
                      {allShippingAddress &&
                        <>
                          {allShippingAddress.map((it: any, index: number) => (
                            <div key={index}>
                              {it.defaut === true &&
                                <>
                                  <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0 p-4 mt-4">
                                    <div className="flex justify-between">
                                      <div>{it.address}</div>
                                    </div>
                                    <div>{it.city}, {it.country}</div>
                                    <div onClick={() => handleShowShippingAddress()} className="flex justify-end relative z-10 mt-3 font-medium text-primary-6000 hover:underline hover:text-primary-500 text-sm">
                                      <span className="cursor-pointer">Modifier d'adresse de livraison</span>
                                    </div>
                                  </div>
                                </>
                              }
                            </div>
                          ))}
                        </>
                      }
                    </>
                  }

                  {editShipping &&
                    <>
                      {allShippingAddress.filter((it: any) => it.defaut === true).map((it: any, index: number) => (

                        <div className="mt-8" key={index}>
                          <AutoComplete
                            apiKey={GOOGLE_MAP_API}
                            onPlaceSelected={(place: any) => {
                              setShippingAddress(place)
                            }}
                            defaultValue={it?.address}
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5'
                          />

                          <div className="flex flex-row">
                            <div className="mt-4 w-full mr-2">
                              <Label>Code postal</Label>
                              <Input type="text" className="mt-1.5" value={address['postalCode'] ? address['postalCode'] : it?.postalCode} disabled />
                            </div>

                            <div className="mt-4 w-full ml-2">
                              <Label>Ville</Label>
                              <Input type="text" className="mt-1.5" value={address['city'] ? address['city'] : it?.city} disabled />
                            </div>
                          </div>

                          <div className="mt-4 w-full mr-2">
                            <Label>Pays</Label>
                            <Input type="text" className="mt-1.5" value={address['country'] ? address['country'] : it?.country} disabled />
                          </div>

                          <div className="flex justify-start mt-4">
                            <ButtonPrimary onClick={() => handleEditShippingAddress(it?._id)}>
                              Modifier
                            </ButtonPrimary>
                          </div>

                        </div>
                      ))}
                    </>
                  }

                </div>
              }

              {auth?.token &&
                <>
                  {(user?.shippingAddress === undefined || user?.shippingAddress.length === 0) &&
                    <>
                      <div className="mt-8">
                        <AutoComplete
                          apiKey={GOOGLE_MAP_API}
                          onPlaceSelected={(place: any) => {
                            setShippingAddress(place)
                          }}
                          options={{
                            types: ["geocode", "establishment"],
                          }}
                          className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5'
                        />
                        <div className="flex flex-row">
                          <div className="mt-4 w-full mr-2">
                            <Label>Code postal</Label>
                            <Input type="text" className="mt-1.5" defaultValue={address['postalCode']} disabled />
                          </div>
                          <div className="mt-4 w-full ml-2">
                            <Label>Ville</Label>
                            <Input type="text" className="mt-1.5" defaultValue={address['city']} disabled />
                          </div>
                        </div>
                        <div className="mt-4 w-full mr-2">
                          <Label>Pays</Label>
                          <Input type="text" className="mt-1.5" defaultValue={address['country']} disabled />
                        </div>
                      </div>
                    </>
                  }
                </>
              }

              {auth?.token &&
                <h1 className="font-[500] text-[19px] mt-8">Adresse de facturation</h1>
              }

              {(auth?.token && user?.billingAddress === undefined) &&
                <>
                  <div className={`mt-8`}>
                    <div className="mt-8">
                      <AutoComplete
                        apiKey={GOOGLE_MAP_API}
                        onPlaceSelected={(place: any) => {
                          setBillingAddress(place)
                        }}
                        options={{
                          types: ["geocode", "establishment"],
                        }}
                        className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5'
                      />

                      <div className="flex flex-row">
                        <div className="mt-4 w-full mr-2">
                          <Label>Code postal</Label>
                          <Input type="text" className="mt-1.5" defaultValue={addressBilling['postalCode']} disabled />
                        </div>

                        <div className="mt-4 w-full ml-2">
                          <Label>Ville</Label>
                          <Input type="text" className="mt-1.5" defaultValue={addressBilling['city']} disabled />
                        </div>
                      </div>

                      <div className="mt-4 w-full mr-2">
                        <Label>Contry</Label>
                        <Input type="text" className="mt-1.5" defaultValue={addressBilling['country']} disabled />
                      </div>

                    </div>
                  </div>
                </>
              }

              {!editBilling &&
                <>
                  {(user?.billingAddress && auth.token) &&
                    <>
                      <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0 p-4 mt-4">
                        <div className="flex justify-between">
                          <div>{user?.billingAddress.address}</div>
                        </div>
                        <div>{user?.billingAddress.city}, {user?.billingAddress.country}</div>
                        <div className="flex justify-end relative z-10 mt-3 font-medium text-primary-6000 hover:underline hover:text-primary-500 text-sm">
                          <span onClick={() => handleShowBillingAddress()} className="cursor-pointer">Modifier l'adresse de facturation</span>
                        </div>
                      </div>
                    </>
                  }
                </>
              }

              {editBilling &&
                <>
                  <div className={`mt-8`}>
                    <div className="mt-8">
                      <AutoComplete
                        apiKey={GOOGLE_MAP_API}
                        onPlaceSelected={(place: any) => {
                          setBillingAddress(place)
                        }}
                        options={{
                          types: ["geocode", "establishment"],
                        }}
                        defaultValue={user?.billingAddress.address}
                        className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-2xl text-sm font-normal h-11 px-4 py-3 mt-1.5'
                      />

                      <div className="flex flex-row">
                        <div className="mt-4 w-full mr-2">
                          <Label>Code postal</Label>
                          <Input type="text" className="mt-1.5" value={addressBilling['postalCode'] ? addressBilling['postalCode'] : user?.billingAddress.postalCode} disabled />
                        </div>

                        <div className="mt-4 w-full ml-2">
                          <Label>Ville</Label>
                          <Input type="text" className="mt-1.5" value={addressBilling['city'] ? addressBilling['city'] : user?.billingAddress.city} disabled />
                        </div>
                      </div>

                      <div className="mt-4 w-full mr-2">
                        <Label>Contry</Label>
                        <Input type="text" className="mt-1.5" value={addressBilling['country'] ? addressBilling['country'] : user?.billingAddress.country} disabled />
                      </div>

                    </div>

                    <div onClick={() => handleEditBillingAddress()} className="flex justify-start mt-4">
                      <ButtonPrimary>
                        Modifier
                      </ButtonPrimary>
                    </div>

                  </div>

                </>
              }
            </div>

            <div className="w-full">
              {auth?.token &&
                <div className="w-full flex justify-end hidden lg:flex xl:flex mt-6"> {/* Utilisation de "flex" et "justify-end" pour aligner à droite */}
                  <ButtonPrimary onClick={() => handleGoToNextStep()} className="h-1/4 flex justify-end">
                    Continuer
                  </ButtonPrimary>
                </div>
              }
            </div>
          </div>

          <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12 block lg:hidden xl:hidden" />

          <>
            <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-0 lg:my-0 lg:mx-10 flex-shrink-0 hidden lg:block xl:block"></div>
            <div className="flex-1">
              <div className="sticky top-28">
                <div className="order-1">
                  <h3 className="text-lg font-semibold mb-4">Récapitulatif de la commande</h3>

                  <div className="border-[1px] rounded p-3">
                    <div className="flex justify-between font-[400]">
                      <span className="text-slate-500">{cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"} dans votre panier - {cart?.discount ? cart?.discountedPrice?.toFixed(2) : cart?.cartPrice?.toFixed(2)} €</span>
                      <span onClick={() => handleCollapseOrderSummary()} className="font-[500] text-primary-6000 text-[14px] hover:underline hover:text-primary-500 cursor-pointer">{collaspeOrderSummary ? "Fermer le détail" : "Voir le détail"}</span>
                    </div>
                  </div>

                  {collaspeOrderSummary &&
                    <div className="mt-8">
                      {renderProduct()}
                      <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80 block lg:hidden xl:hidden">
                        <div className="divide-y divide-slate-200/70 dark:divide-slate-700/80">
                          <div className="flex flex-row mb-5">
                            <div className="flex w-full mr-4">
                              <Input className="w-full" onChange={(e: any) => setDiscountCode(e.target.value)} placeholder="Code promo" />
                            </div>
                            <ButtonPrimary onClick={() => handleAddDiscountCode()} className="h-1/4 w-1/4">
                              Ajouter
                            </ButtonPrimary>
                          </div>
                        </div>
                        <div className="flex justify-between pb-4">
                          <span className="mt-4">Sous-total ({cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"})</span>
                          <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                            {cart?.discountedPriceWithoutTaxe ? cart?.discountedPriceWithoutTaxe : cart?.cartPriceWithoutTaxe?.toFixed(2)}
                          </span>
                        </div>


                        {cart?.taxePrice !== undefined &&
                          <div className="flex justify-between pb-4">
                            <span className="mt-4">Dont TVA</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                              {cart?.taxePrice?.toFixed(2)} €
                            </span>
                          </div>
                        }

                        {cart.discountCode &&
                          <div className="flex justify-between pb-4">
                            <span className="mt-4">Remise ({cart?.discountCode})</span>
                            <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                              - {cart?.discount?.amount}
                              {cart?.discount?.type === "percentage" ? "%" : "€"}
                            </span>
                            <span className="mt-4 cursor-pointer" onClick={() => handleDeleteDiscountCode()}>
                              <button className="w-5 h-5 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ">
                                <span className="text-[12px]">x</span>
                              </button>
                            </span>
                          </div>
                        }

                        <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                          <div>
                            <span>{t("Order_Total")}</span>
                            <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                          </div>
                          {cart?.discount?.amount ? (cart?.discountedPrice)?.toFixed(2) : cart?.cartPrice?.toFixed(2)} €
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80 hidden lg:block xl:block">
                  <div className="divide-y divide-slate-200/70 dark:divide-slate-700/80">
                    <div className="flex flex-row mb-5">
                      <div className="flex w-full mr-4">
                        <Input className="w-full" onChange={(e: any) => setDiscountCode(e.target.value)} placeholder="Code promo" />
                      </div>
                      <ButtonPrimary onClick={() => handleAddDiscountCode()} className="h-1/4 w-1/4">
                        Ajouter
                      </ButtonPrimary>
                    </div>
                  </div>
                  <div className="flex justify-between pb-4">
                    <span className="mt-4">Sous-total ({cart?.cartLength} {cart?.cartLength === 1 ? "article" : "articles"})</span>
                    <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                      {cart?.discountedPriceWithoutTaxe ? cart?.discountedPriceWithoutTaxe?.toFixed(2) : cart?.cartPriceWithoutTaxe?.toFixed(2)} €
                    </span>
                  </div>

                  {cart?.taxePrice !== undefined &&
                    <div className="flex justify-between pb-4">
                      <span className="mt-4">Dont TVA</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                        {cart?.taxePrice?.toFixed(2)} €
                      </span>
                    </div>
                  }

                  {cart.discountCode &&
                    <div className="flex justify-between pb-4">
                      <span className="mt-4">Remise ({cart?.discountCode})</span>
                      <span className="font-semibold text-slate-900 dark:text-slate-200 mt-4">
                        - {cart?.discount?.amount}
                        {cart?.discount?.type === "percentage" ? "%" : "€"}
                      </span>
                      <span className="mt-4 cursor-pointer" onClick={() => handleDeleteDiscountCode()}>
                        <button className="w-5 h-5 flex items-center justify-center rounded-full bg-slate-200 dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ">
                          <span className="text-[12px]">x</span>
                        </button>
                      </span>
                    </div>
                  }


                  <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                    <div>
                      <span>{t("Order_Total")}</span>
                      <p className="text-slate-500 text-xs font-normal">Taxes incluses</p>
                    </div>
                    {cart?.discount?.amount ? (cart?.discountedPrice)?.toFixed(2) : cart?.cartPrice?.toFixed(2)} €
                  </div>
                </div>
              </div>
            </div>
          </>
          <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12 block lg:hidden xl:hidden" />
        </div>

        {auth?.token &&
          <div className="flex w-full mt-6 block lg:hidden xl:hidden">
            <ButtonPrimary onClick={() => handleGoToNextStep()} className="h-1/4 w-full">
              Continuer
            </ButtonPrimary>
          </div>
        }

      </main>
    </div>
  );
};

export default CheckoutInformationPage;