import api from "../api/store";

// GET STRIPE PUBLIC KEY
export const getStore = () => {
    return api
        .getStore()
        .then(async (result: any) => {
            if (result) {
                return result?.data
            }
        })
};