import { USER_INFO_SUCCESS, USER_INFO_FAIL, SUCCESS_ADD_SHIPPING_ADDRESS } from "./types";
import api from "../api/user";
import { User } from "models/User";

// GET USER INFOS
export const getUserInfos = (): any =>
    async (dispatch: any) => {
        return api
            .getUserInfos()
            .then(async (result: any) => {
                if (result.data) {

                    dispatch({
                        type: USER_INFO_SUCCESS,
                        payload: result.data
                    });
                    return result.data
                }
            })

            .catch(async (err: any) => {
                dispatch({
                    type: USER_INFO_FAIL,
                    error: err
                });

                return false
            });
    };

// CHANGE PASSWORD
export const changePassword = (currPass: string, newPass: string): any => {
    return api
        .changePassword(currPass, newPass)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// UPDATE USER INFOS
export const updateUserInfos = (updatedUser: User): any =>
    async (dispatch: any) => {
        return api
            .updateUserInfos(updatedUser)
            .then(async (result: any) => {
                if (result.data) {

                    dispatch({
                        type: USER_INFO_SUCCESS,
                        payload: result.data
                    });
                    return result.data
                }
            })

            .catch(async (err: any) => {
                dispatch({
                    type: USER_INFO_FAIL,
                    error: err
                });

                return false
            });
    };

// ADD SHIPPING ADDRESS
export const addShippingAddress = (address: object): any =>
    async (dispatch: any) => {
        return api
            .addShippingAddress(address)
            .then(async (result: any) => {
                if (result.data) {

                    dispatch({
                        type: SUCCESS_ADD_SHIPPING_ADDRESS,
                        payload: result.data
                    })

                    return result.data
                }
            })
    };

// GET ALL SHIPPING ADDRESS
export const getAllShippingAddress = (): any => {
    return api
        .getAllShippingAddress()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// DELETE SHIPPING ADDRESS
export const deleteShippingAddress = (id: string): any =>
    async (dispatch: any) => {
        return api
            .deleteShippingAddress(id)
            .then(async (result: any) => {
                if (result.data) {

                    dispatch({
                        type: SUCCESS_ADD_SHIPPING_ADDRESS,
                        payload: result.data
                    })

                    return result.data
                }
            })
    };

// EDIT SHIPPING ADDRESS
export const editShippingAddress = (address: object): any =>
    async (dispatch: any) => {
        return api
            .editShippingAddress(address)
            .then(async (result: any) => {
                if (result.data) {
                    dispatch({
                        type: SUCCESS_ADD_SHIPPING_ADDRESS,
                        payload: result.data
                    })

                    return result.data
                }
            })
    };

// GET SHIPPING ADDRESS BY ID
export const getShippingAddressById = (shippingAdressId: string): any => {
    return api
        .getShippingAddressById(shippingAdressId)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};
