import { Helmet } from "react-helmet";
import Img1 from "images/img99.png";

const RecetteAutomne = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Recette automne 2021 | Conserverie Bertin</title>
            </Helmet>


            {/* OUR TEAMS  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8 mb-14">

                            <div className="flex justify-center flex flex-col mb-14 mt-10">
                                <h1 className="uppercase text-[45px] text-[#777] font-julius leading-[55px] font-[600] text-center lg:whitespace-nowrap md:whitespace-nowrap xl:whitespace-nowrap">RECETTE AUTOMNE 2021</h1>
                                <div className="flex justify-center mt-4">
                                    <img className="w-1/4" src={Img1} />
                                </div>
                            </div>


                            <div className="max-w-5xl text-[14px] font-normal ">
                                <h1 className="mb-4">Pour 2 personnes:</h1>
                                <ul className="list-disc ml-8 font-semibold">
                                    <li>2 belles tranches de pain de campagne 1 oeuf</li>
                                    <li>1 petit pot de soupe de langoustine</li>
                                    <li>2 càs de parmesan</li>
                                    <li>10 g de beurre</li>
                                </ul>
                                <p className="leading-[28px] mt-4">Dans un plat à bord haut, versez la moitié de la soupe froide, rajoutez le parmesan, l’œuf, le sel et le poivre puis mélangez à la fourchette afin d’homogénéiser la préparation. Faire tremper les tranches de pain 10 à 15 minutes dans le mélange et retourner afin que tout le pain soit bien imbiber.<br />Faire chauffer le reste de soupe dans une casserole.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RecetteAutomne;
