import api from "../api/products";

// GET ALL PRODUCTS
export const getAllProducts = async () => {
    return api
        .getAllProducts()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET ALL PRODUCTS FILTER
export const getAllProductsFilter = async (data: any) => {
    return api
        .getAllProductsFilter(data)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET ALL FILTER
export const getAllFilter = async (data: any) => {
    return api
        .getAllFilter(data)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET PRODUCTS BY ID
export const getProductById = async (id: string) => {
    return api
        .getProductById(id)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET PRODUCTS BY SLUG
export const getProductBySlug = async (slug: string) => {
    return api
        .getProductBySlug(slug)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET PRODUCTS BY CATEGORIE
export const getProductByCategorie = async (id: string) => {
    return api
        .getProductByCategorie(id)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET ALL NEW PRODUCTS
export const getAllNewProduct = async () => {
    return api
        .getAllNewProduct()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET BEST SELLER
export const getBestSeller = async () => {
    return api
        .getBestSeller()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};