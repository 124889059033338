import { LOGIN_SUCCESS, LOGOUT, REGISTER_SUCCESS } from '../../actions/types'

const defaultState = {}

export default (state = defaultState, action: any) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case LOGIN_SUCCESS:
            return { ...state, ...payload };
        case REGISTER_SUCCESS:
            return { ...state, ...payload };
        case LOGOUT:
            return state;
        default:
            return state;
    }
}   