import api from "../api/orders";

// ADD ORDER
export const addOrder = (data: object): any => {
    return api
        .addOrder(data)
        .then(async (result: any) => {

            if (result) {
                return result.data
            }
        })
};

// GET ALL ORDERS
export const getAllOrders = (): any => {
    return api
        .getAllOrders()
        .then(async (result: any) => {

            if (result) {
                return result.data
            }
        })
};

// GET ORDER PREVIEW
export const getOrderPreview = (): any => {
    return api
        .getOrderPreview()
        .then(async (result: any) => {

            if (result) {
                return result.data
            }
        })
};

// GET ORDER PREVIEW IN ANONYMOUS CART
export const getOrderPreviewInAnonymousCart = (anonymousCartId: string): any => {
    return api
        .getOrderPreviewInAnonymousCart(anonymousCartId)
        .then(async (result: any) => {

            if (result) {
                return result.data
            }
        })
};

