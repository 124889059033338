import { apiClient } from './apiClient'

function getAxeptio() {
    return apiClient().get('/axeptio')
}

function getHotjar() {
    return apiClient().get('/hotjar')
}

function getAnalytics() {
    return apiClient().get('/analytics')
}

export default {
    getAxeptio,
    getHotjar,
    getAnalytics
}
