import { Helmet } from "react-helmet";
import Img1 from "images/img98.png";

const RecetteEte = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Recette été 2021 | Conserverie Bertin</title>
            </Helmet>


            {/* OUR TEAMS  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8 mb-14">

                            <div className="flex justify-center flex flex-col mb-14 mt-10">
                                <h1 className="uppercase text-[45px] text-[#777] font-julius leading-[55px] font-[600] text-center lg:whitespace-nowrap md:whitespace-nowrap xl:whitespace-nowrap uppercase">RECETTE été 2021</h1>
                                <div className="flex justify-center mt-4">
                                    <img className="w-1/4" src={Img1} />
                                </div>
                            </div>


                            <div className="max-w-5xl text-[14px] font-normal ">
                                <h1 className="mb-4">Pour 2 personnes:</h1>
                                <ul className="list-disc ml-8 font-semibold">
                                    <li>500g de moules de bouchots par personne</li>
                                    <li>1 petit pot de soupe 2 échalotes</li>
                                    <li>10 CL de vin blanc 1 Càs d’huile d’olive</li>
                                    <li>Persil ou coriandre</li>
                                </ul>
                                <p className="leading-[28px] mt-4">Dans un casserole faire chauffer la soupe. Dans un grand récipient, faire revenir les échalotes hachées dans l’huile d’olive puis verser les moules, rajouter le vin blanc, le persil puis couvrir avec un couvercle. Remuer les moules, et une fois ouvertes verser la soupe de poisson chaude sur les moules<br />Servir chaud avec des frites maison</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default RecetteEte;
