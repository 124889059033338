import React from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  description?: React.ReactNode;
  title?: React.ReactNode;
}

export const Alert: React.FC<AlertProps> = ({
  description = "Alert description",
  title = "Alert title",
  containerClassName = "",
  type = "default",
}) => {
  let classes = containerClassName;
  switch (type) {
    case "success":
      classes += " bg-green-100 border-green-400 text-green-700 ";
      break;
    case "error":
      classes += " bg-red-100 border-red-400 text-red-700 ";
      break;
    case "warning":
      classes += " bg-orange-100 border-orange-300 text-orange-700";
      break;
    default:
      break;
  }

  return (
    <div className={`${classes} border px-4 py-3 rounded relative mb-4`} role="alert">
      <div>
        <ButtonClose className="absolute right-1" />
      </div>
      <strong className="font-bold">{title}</strong>
      <span className="block sm:inline">{description}</span>

    </div>
  )
};
