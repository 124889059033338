import React, { useEffect, useState } from 'react';
import { getHotjar } from 'actions/integrations';

const HotjarScript = () => {
    const [hotjarScript, setHotjarScript] = useState();

    const loadHotjarScript = () => {
        // Check if axeptioScript is not empty before appending to the DOM
        if (hotjarScript) {
            const scriptElement = document.createElement('script');
            scriptElement.innerHTML = hotjarScript;
            document.head.appendChild(scriptElement);
        }
    };

    useEffect(() => {
        getHotjar()
            .then((res) => {
                setHotjarScript(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        loadHotjarScript();
    }, [hotjarScript]);

    return null;
};

export default HotjarScript;
