import { apiClient } from './apiClient'

function addOrder(data: object) {
    return apiClient().post('/order', data)
}

function getAllOrders() {
    return apiClient().get('/order')
}

function getOrderPreview() {
    return apiClient().get('/order/preview')
}

function getOrderPreviewInAnonymousCart(anonymousCartId: string) {
    return apiClient().get('/order/anonymous/' + anonymousCartId + '/preview')
}

export default {
    addOrder,
    getAllOrders,
    getOrderPreview,
    getOrderPreviewInAnonymousCart
}
