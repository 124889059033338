import { Helmet } from "react-helmet";

const Cgv = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>CGV | Conserverie Bertin</title>
            </Helmet>


            <div className="flex justify-center py-10">
                <div className="max-w-5xl p-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold mb-4">CONDITIONS GENERALES DE VENTE</h1>
                        <p className="mb-4">SOCIETE PECHERIE SEUDRE-ATLANTIQUE</p>
                        <p className="mb-4">SAS unipersonnelle au capital de 12 196 euros</p>
                        <p className="mb-4">Siège social : 1 bis rue Pierre Loti – 17390 LA TREMBLADE</p>
                        <p className="mb-4">384 683 967 RCS LA ROCHELLE</p>
                    </div>

                    <p className="mb-4 mt-14">Article 1 - Contenu et champ d'application
                        Les présentes conditions générales de vente s'appliquent de plein droit à
                        toutes ventes de conserves alimentaires de soupe de poissons réalisées
                        sous le nom commercial « CONSERVERIE BERTIN » exploité par la société
                        PECHERIE SEUDRE-ATLANTIQUE, Vendeur.
                        Les présentes conditions générales ont pour objet de définir les droits et
                        obligations des parties dans le cadre de la vente en ligne de produits
                        alimentaires proposés au détail par le vendeur à l'acheteur, à partir du
                        site internet https://conserverie-bertin.fr/.
                        Les présentes conditions ne concernent que les achats effectués par les
                        acheteurs situés en France et livrés exclusivement sur le territoire
                        français. Pour toute livraison hors de France, il convient d'adresser un
                        message à conserveriebertin@gmail.com
                        Ces achats concernent les produits figurant au catalogue mis en ligne.
                        Elles s'appliquent à l'exclusion de toutes autres conditions, et notamment
                        celles applicables pour les ventes en magasins ou au moyen d'autres
                        circuits de distribution et de commercialisation.
                        Préalablement à cette date, les présentes conditions de vente sont mises
                        à la disposition de tout acheteur à titre informatif et sont disponibles sur
                        le site internet https://conserverie-bertin.fr/cgv/.
                        Toute commande implique l'adhésion sans réserve aux présentes
                        conditions générales de vente qui prévalent sur toutes autres conditions
                        et le cas échéant toutes autres versions ou tout autre document
                        contradictoire, à l'exception de celles qui ont été acceptées expressément
                        par le vendeur.
                        Le fait, pour l’Acheteur, de cocher la case « je reconnais avoir lu et
                        accepté les conditions générales de vente » avant la validation de toute
                        commande, entraine d’office son acceptation expresse et sans réserve à
                        l’ensemble des présentes conditions générales de vente.
                        Le Vendeur et l’Acheteur conviennent que les présentes conditions
                        générales régissent exclusivement leur relation. Le Vendeur se réserve le
                        droit de modifier ponctuellement ses conditions générales. Elles seront
                        applicables dès leur mise en ligne.
                        Si une condition venait à faire défaut, elle serait considérée comme étant
                        régie par les usages en vigueur dans le secteur de la vente à distance de
                        produits alimentaires au détail dont les sociétés ont leur siège en France.
                        L'acheteur déclare avoir pris connaissance des présentes conditions
                        générales de vente et les avoir acceptées avant son achat immédiat ou la
                        passation de sa commande. A cet égard, elles lui sont opposables
                        conformément aux termes de l'article 1119 du code civil.
                        En cas de discordance entre les conditions générales et des conditions
                        particulières, ces dernières prévalent sur les premières.
                        Les présentes conditions générales de vente sont applicables à compter
                        du 20/06/2022.</p>

                    <p className="mb-4">Article 2 - Information précontractuelle
                        Préalablement à l'achat immédiat ou à la passation de la commande et à
                        la conclusion du contrat, ces conditions générales de vente sont
                        communiquées à l'acheteur, qui reconnaît les avoir reçues.
                        L'acheteur reconnaît avoir eu communication, préalablement à la
                        passation de sa commande et à la conclusion du contrat, d'une manière
                        lisible et compréhensible, des présentes conditions générales de vente et
                        de toutes les informations listées aux articles L. 221-5 et L412-8 du code
                        de la consommation.
                        Lui sont notamment transmises de manière claire et compréhensible, les
                        informations suivantes :
                        - les caractéristiques essentielles du produit ;
                        - et les informations sur les denrées alimentaires ;
                        - le prix des produits et des frais annexes ;
                        - le mode de calcul du prix et, s'il y a lieu, tous les frais supplémentaires
                        de transport, de livraison, d’installation et tous les autres frais éventuels.
                        Lorsque les frais supplémentaires ne peuvent raisonnablement être
                        calculés à l'avance, le professionnel mentionne qu'ils peuvent être
                        exigibles.
                        - les informations relatives à l’identité du prestataire, à ses coordonnées
                        postales, téléphoniques et électroniques, et à ses activités,
                        - la date ou le délai auquel le vendeur s'engage à livrer le produit ; et, le
                        cas échéant,
                        - les informations relatives aux garanties légales et contractuelles et à
                        leurs modalités de mise en œuvre ;
                        - la possibilité de recourir à une médiation conventionnelle en cas de
                        litige,
                        - l’impossibilité d’exercer de droit de rétractation.
                        Le vendeur communique à l'acheteur les informations suivantes :
                        - son nom ou sa dénomination sociale, l'adresse géographique de son
                        établissement et, si elle est différente, celle du siège social, son numéro
                        de téléphone et son adresse électronique ;
                        - les modalités de paiement, de livraison et d'exécution du contrat de
                        vente, ainsi que les modalités prévues par le professionnel pour le
                        traitement des réclamations ;
                        - en cas de vente, l'existence et les modalités d'exercice de la garantie
                        légale de conformité prévue aux articles L. 217-1 et suivants du code de
                        la consommation, de la garantie des vices cachés prévue aux
                        articles 1641 et suivants du code civil, ainsi que, le cas échéant, de la
                        garantie commerciale et du service après-vente respectivement visés aux
                        articles L. 217-15 et L. 217-17 du code de la consommation ;</p>

                    <p className="mb-4">Article 3 – Passage de la commande
                        Pour toute commande, l’acheteur a la possibilité de créer un compte
                        client ou de poursuivre sans création d’un compte.
                        L'acheteur a la possibilité de passer sa commande en ligne en accédant à
                        son compte et en alimentant son panier avec les produits disponibles sur
                        le catalogue produits en ligne. Son panier comprend donc l’ensemble des
                        produits choisis sur le site internet.
                        Pour valider la commande, l’Acheteur doit communiquer à minima ses
                        données d’identification, s’il possède ou créé un compte client, et dans
                        tous les cas Nom et Prénom, adresse, numéro de téléphone, adresse e-mail,
                        nationalité, date de naissance et adresse de facturation et, le cas échéant,
                        des informations complémentaires.
                        A ce titre, l’Acheteur s’engage à fournir au vendeur des informations
                        exactes lui permettant d’exécuter l’ensemble de ses obligations
                        contractuelles. La responsabilité du Vendeur ne saurait être engagée dans
                        l’hypothèse où les informations fournies seraient fausses ou erronées.
                        Les commandes portent sur les produits standards qui sont des produits
                        fabriqués, conditionnés et en stocks (dans la limite des stocks disponibles)
                        avec une date de péremption.
                        En cas d'indisponibilité d'un produit commandé, l'acheteur en sera
                        informé par la plateforme de commande.
                        Pour que la commande soit validée, l'acheteur devra accepter, en cliquant
                        à l'endroit indiqué, les présentes conditions générales de vente. Il devra
                        CONDITIONS GENERALES DE VENTE
                        SOCIETE PECHERIE SEUDRE-ATLANTIQUE
                        SAS unipersonnelle au capital de 12 196 euros
                        Siège social : 1 bis rue Pierre Loti – 17390 LA TREMBLADE
                        384 683 967 RCS LA ROCHELLE
                        aussi choisir l'adresse et le mode de livraison, et enfin valider le mode de
                        paiement.
                        La vente sera considérée comme définitive :
                        - après l'envoi à l'acheteur de la confirmation de l'acceptation de la
                        commande par le vendeur par courrier électronique ;
                        - et après encaissement par le vendeur de l'intégralité du prix.
                        Toute commande vaut acceptation des prix et descriptions des produits
                        disponibles à la vente. Toute contestation sur ce point interviendra dans
                        le cadre d'un éventuel échange et des garanties ci-dessous mentionnées.
                        Dans certains cas, notamment défaut de paiement, adresse erronée ou
                        autre problème sur le compte ou informations transmises par l'acheteur,
                        le vendeur se réserve le droit de bloquer la commande de l'acheteur
                        jusqu'à la résolution du problème.
                        L'annulation de la commande de ce Produit et son éventuel
                        remboursement seront alors effectués, le reste de la commande
                        demeurant ferme et définitif.</p>


                    <p className="mb-4">Article 4 - Informations sur les produits
                        Les produits régis par les présentes conditions générales sont ceux qui
                        figurent sur le site internet du vendeur et qui sont indiqués comme
                        vendus et expédiés par le vendeur.
                        Les produits sont entendus comme étant des denrées alimentaires au
                        sens de l’article 2 du règlement du parlement européen et du conseil. La
                        date limite de consommation (DLC) des produits figure sur le site internet
                        pour chaque produit et est indiquée sur l’emballage des produits.
                        Les photographies des produits ne sont pas contractuelles.
                        L’ensemble des caractéristiques produits sont détaillés dans les fiches
                        produits figurant sur le site internet https://conserverie-
                        bertin.mdici.fr/cat/produits/boutique.
                        Les produits proposés à la vente sont conformes à la législation,
                        réglementation et normes françaises et européennes.</p>


                    <p className="mb-4">Article 5 – Prix
                        Le vendeur se réserve le droit de modifier ses prix à tout moment mais
                        s'engage à appliquer les tarifs en vigueur indiqués au moment de la
                        commande, sous réserve de disponibilité à cette date.
                        Les prix sont indiqués en euros.
                        Ils tiennent compte des frais de livraison, facturés lors de la commande,
                        et indiqués avant la validation de la commande. Ceux-ci peuvent varier en
                        fonction de différents critères tels que notamment la zone géographique,
                        la volumétrie des produits, le coût des matières premières, le
                        conditionnement, le mode de livraison.
                        Les prix tiennent compte de la TVA applicable au jour de la commande et
                        tout changement du taux applicable TVA sera automatiquement
                        répercuté sur le prix des produits de la boutique en ligne. Le paiement de
                        la totalité du prix doit être réalisé lors de la commande. A aucun moment,
                        les sommes versées ne pourront être considérées comme des arrhes ou
                        des acomptes.
                        Si une ou plusieurs taxes ou contributions, notamment
                        environnementales, venaient à être créées ou modifiées, en hausse
                        comme en baisse, ce changement pourra être répercuté sur le prix de
                        vente des produits.
                        Le prix est payable en totalité et en un seul versement comptant à la
                        commande.</p>

                    <p className="mb-4">Article 6 – Mode de paiement
                        Il s'agit d'une commande avec obligation de paiement, ce qui signifie que
                        la passation de la commande implique un règlement de l'acheteur.
                        Pour régler sa commande, l'acheteur dispose, à son choix, de l'ensemble
                        des modes de paiement mis à sa disposition par le vendeur et listés sur le
                        site du vendeur.
                        L'acheteur garantit au vendeur qu'il dispose des autorisations
                        éventuellement nécessaires pour utiliser le mode de paiement choisi par
                        lui, lors de la validation du bon de commande.
                        Le vendeur se réserve le droit de suspendre toute gestion de commande
                        et toute livraison en cas de refus d'autorisation de paiement de la part
                        des organismes officiellement accrédités ou en cas de non-paiement.
                        Le vendeur se réserve notamment le droit de refuser d'effectuer une
                        livraison ou d'honorer une commande émanant d'un acheteur qui
                        n'aurait pas réglé totalement ou partiellement une commande
                        précédente ou avec lequel un litige de paiement serait en cours
                        d'administration.
                        Paiement immédiat par carte bancaire
                        Le Vendeur peut procéder au règlement immédiat de sa commande par
                        carte bancaire (Carte Bleue, visa, Mastercard).
                        L’Acheteur est, dans ce cas, immédiatement débité sur sa carte bancaire
                        après vérifications des données de celle-ci et à réception de l’autorisation
                        de débit de la part de la société émettrice de la carte bancaire utilisée par
                        l’Acheteur.
                        Conformément à l’article L132-2 du code monétaire et financier,
                        l’engagement de payer donné au moyen d’une carte de paiement est
                        irrévocable. En communiquant les informations relatives à sa carte
                        bancaire, l’Acheteur autorise expressément le Vendeur à débiter sa carte
                        bancaire du montant correspondant au montant total du panier.
                        L’Acheteur qui passe la commande reconnait être titulaire de la carte
                        bancaire avec laquelle il procède au paiement et disposer des fonds
                        nécessaires au paiement de sa commande.
                        Si la banque de l’Acheteur refuse le paiement, la commande est
                        automatiquement annulée.
                        Les paiements effectués par l'acheteur ne seront considérés comme
                        définitifs qu'après encaissement effectif des sommes dues, par le
                        vendeur.
                        L’Acheteur reçoit un mail de confirmation de commande et de paiement.</p>

                    <p className="mb-4">Article 7 - Modalités de livraison
                        Sauf en cas de force majeure ou lors des périodes de fermeture de la
                        boutique en ligne qui seront clairement annoncées sur la page d'accueil
                        du site, les délais d'expédition seront ceux indiqués ci-dessous. Les délais
                        d'expédition courent à compter de la date de validation définitive de la
                        commande indiquée sur le mail de confirmation de la commande.
                        Pour les livraisons en France Métropolitaine de produits en stock, le délai
                        est de dix jours ouvrables à compter du jour suivant celui où la
                        commande est devenue définitive.
                        CONDITIONS GENERALES DE VENTE
                        SOCIETE PECHERIE SEUDRE-ATLANTIQUE
                        SAS unipersonnelle au capital de 12 196 euros
                        Siège social : 1 bis rue Pierre Loti – 17390 LA TREMBLADE
                        384 683 967 RCS LA ROCHELLE
                        Les produits sont livrés selon les modalités suivantes :
                        - Par Mondial Relay en 48H / 72H, en point relais
                        - Par Chronopost / Chronopost Food Sec le lendemain de l’expédition
                        avant 13H, en point relais ou au domicile au choix de l’acheteur,
                        Les expéditions sont réalisées les jours ouvrables.
                        Les produits sont livrés au point relais indiqué par l’acheteur ou à
                        l'adresse indiquée par l'acheteur lors de la commande, l'acheteur devra
                        veiller à son exactitude.
                        Tout produit renvoyé au vendeur à cause d'une adresse de livraison
                        erronée ou incomplète sera réexpédié aux frais de l'acheteur. L'acheteur
                        peut, à sa demande, obtenir l'envoi d'une facture à l'adresse de
                        facturation et non à l'adresse de livraison, en validant l'option prévue à
                        cet effet au moment de la commande.
                        Pour les livraisons à domicile :
                        Si l’Acheteur est absent, le livreur laissera un avis de passage dans la boîte
                        aux lettres, qui permettra de retirer le colis à l'endroit et pendant le délai
                        indiqué.
                        Pour les autres livraisons réalisées en point relais :
                        L’Acheteur dispose d’un délai de 14 jours pour retirer son colis en point
                        relais, passé ce délai il sera détruit. Le Vendeur ne pourra en aucun cas
                        être tenu responsable d’une éventuelle détérioration des produits du fait
                        de leur retrait tardif de la part de l’Acheteur.
                        Si au moment de la livraison, l'emballage d'origine est abîmé, déchiré,
                        ouvert, l'acheteur doit alors vérifier l'état des produits. S'ils ont été
                        endommagés, l'acheteur doit impérativement refuser le colis et noter une
                        réserve sur le bordereau de livraison (colis refusé car ouvert ou
                        endommagé).
                        En cas de retard de livraison pour quelques raisons que ce soit, le client
                        sera informé du retard de livraison et le nouveau délai dans lequel le
                        produit pourra être livré.
                        Ce délai de livraison n'est donné qu'à titre informatif et indicatif, celui-ci
                        dépendant notamment de la disponibilité des matières premières et de
                        l'ordre d'arrivée des commandes, de la durée de fabrication et de
                        conditionnement du produit.
                        Le vendeur s'efforce de respecter le délai de livraison indiqué à
                        l'acceptation de la commande, en fonction du délai de référence dans la
                        profession, et à exécuter les commandes, sauf cas force majeure, ou en
                        cas de circonstances hors de son contrôle, telles que grèves, gel, incendie,
                        tempête, inondation, épidémie, difficultés d'approvisionnement, sans que
                        cette liste soit limitative.
                        En cas de non-respect de la date ou du délai de livraison convenu,
                        l'acheteur devra, avant de résoudre le contrat, enjoindre au vendeur
                        d'exécuter celui-ci dans un délai supplémentaire raisonnable.
                        A défaut d'exécution à l'expiration de ce nouveau délai, l'acheteur pourra
                        librement résoudre le contrat.
                        L'acheteur devra accomplir ces formalités successives par lettre
                        recommandée avec accusé de réception ou par un écrit sur un autre
                        support durable ou par mail à l’adresse suivante : contact@conserverie-
                        bertin.fr
                        Le contrat sera considéré comme résolu à la réception par le vendeur de
                        la lettre ou de l'écrit l'informant de cette résolution, sauf si le
                        professionnel s'est exécuté entre-temps.
                        L'acheteur pourra cependant résoudre immédiatement le contrat, si les
                        dates ou délais vu ci-dessus constituent pour lui une condition essentielle
                        et déterminante de sa volonté de souscrire le contrat. Cette condition
                        essentielle résulte des circonstances qui entourent la conclusion du
                        contrat ou d'une demande expresse de l’acheteur avant la conclusion du
                        contrat.
                        Dans ce cas, lorsque le contrat est résolu, le vendeur est tenu de
                        rembourser l'acheteur de la totalité des sommes versées, au plus tard
                        dans les 14 jours suivant la date à laquelle le contrat a été dénoncé.
                        Conformément à l'article L. 242-4 du code de la consommation, lorsque le
                        professionnel n'a pas remboursé les sommes versées par le
                        consommateur, les sommes dues sont de plein droit majorées.
                        Le Vendeur pourra toutefois différer le remboursement jusqu'à
                        récupération des biens ou jusqu'à ce que l’Acheteur ait fourni une preuve
                        de l'expédition de ces biens, la date retenue étant celle du premier de ces
                        faits.
                        En cas d'indisponibilité du produit commandé, l'acheteur en sera informé
                        au plus tôt et aura la possibilité d'annuler sa commande. L'acheteur aura
                        alors le choix de demander soit le remboursement des sommes versées
                        dans les 30 jours au plus tard de leur versement, soit l'échange du
                        produit.</p>

                    <p className="mb-4">Article 8 - Erreurs de livraison
                        L'acheteur devra formuler auprès du vendeur le jour même de la livraison
                        ou au plus tard le premier jour ouvré suivant la livraison, toute
                        réclamation d'erreur de livraison et/ou de non-conformité des produits
                        en nature ou en qualité par rapport aux indications figurant sur le bon de
                        commande. Toute réclamation formulée au-delà de ce délai sera rejetée.
                        La réclamation pourra être faite, au choix de l'acheteur :
                        - par téléphone : 05.64.58.00.05 ;
                        - par courrier électronique : conserveriebertin@gmail.com.
                        Toute réclamation non effectuée dans les règles définies ci-dessus et dans
                        les délais impartis ne pourra être prise en compte et dégagera le vendeur
                        de toute responsabilité vis-à-vis de l'acheteur.
                        En cas d'erreur de livraison ou d'échange, tout produit à échanger ou à
                        rembourser devra être retourné au vendeur dans son ensemble et dans
                        son emballage d'origine, selon les modalités suivantes :
                        L’Acheteur dispose d’un délai de 14 jours pour retourner le produit à
                        l’adresse suivante : 1 bis rue Pierre Loti, 17390 LA TREMBLADE.
                        Les frais de retour sont à la charge exclusive du vendeur.</p>

                    <p className="mb-4">Article 9 - Transfert de propriété et transfert des risques
                        Le transfert de propriété des produits ne sera réalisé qu'après complet
                        paiement du prix par l'acheteur, peu importe la date de livraison.
                        En revanche le transfert des risques ne sera réalisé qu’à la date de
                        signature du bon de livraison. Autrement dit, les produits voyagent aux
                        risques et périls du vendeur.</p>

                    <p className="mb-4">Article 10 - Garantie et responsabilité
                        Le vendeur garantit au client que les produits sont fabriqués et
                        conditionnés selon des règles de qualité et d’hygiène strictes et conservés
                        selon les normes en vigueur en France. Dans le cas où le client ne
                        respecterait pas les conditions ou les dates de conservation optimum des
                        CONDITIONS GENERALES DE VENTE
                        SOCIETE PECHERIE SEUDRE-ATLANTIQUE
                        SAS unipersonnelle au capital de 12 196 euros
                        Siège social : 1 bis rue Pierre Loti – 17390 LA TREMBLADE
                        384 683 967 RCS LA ROCHELLE
                        produits, le vendeur ne pourrait être tenu responsable d’une altération
                        de la qualité de ses produits.
                        Sont littéralement reportés en annexe des présentes conditions générales
                        de vente, la reproduction des articles L. 217-4, L. 217-5, L. 217-12 et
                        L. 217-16 du code de la consommation et des articles 1641 et 1648 du
                        code civil.
                        La société PECHERIE SEUDRE ATLANTIQUE est garante de la conformité
                        des produits au contrat, permettant à l'acheteur de formuler une
                        demande au titre de la garantie légale de conformité prévue aux articles
                        L. 217-4 et suivants du code de la consommation ou de la garantie des
                        défauts de la chose vendue au sens des articles 1641 et suivants du code
                        civil.
                        En cas de mise en œuvre de la garantie légale de conformité, il est
                        rappelé que :
                        - l'acheteur bénéficie d'un délai de 2 ans à compter de la délivrance du
                        bien pour agir ;
                        - l'acheteur peut choisir entre la réparation ou le remplacement du bien,
                        sous réserve des conditions de coût prévues par l'article L. 217-17 du
                        code de la consommation ;
                        - l'acheteur est dispensé de rapporter la preuve de l'existence du défaut
                        de conformité du bien durant les 24 mois suivant la délivrance du bien.
                        En outre, il est rappelé que :
                        - l'acheteur peut décider de mettre en œuvre la garantie contre les
                        défauts cachés de la chose vendue au sens de l'article 1641 du code civil.
                        Dans cette hypothèse, il peut choisir entre la résolution de la vente ou
                        une réduction du prix conformément à l'article 1644 du code civil.
                        Sauf accord particulier entre les Parties, la société ne consent aucune
                        garantie contractuelle.
                        L’acheteur est seul responsable de la conservation des Biens à compter
                        du jour de leur livraison.
                        Le Vendeur ne saurait donc voir sa responsabilité engagée pour tous
                        dommages directs ou indirects causés par la faute du Client, notamment
                        la consommation au-delà de la date limite indiquée sur les produits
                        périssables.
                        En cas de mise en jeu de la responsabilité du Vendeur, les sommes de
                        toute nature qui pourraient être mises à sa charge, à quelque titre que ce
                        soit, notamment dommages et intérêts, remboursements et indemnités,
                        etc, ne pourront dépasser le montant du Prix Tout Compris.</p>

                    <p className="mb-4">Article 11 – Droit de rétractation - Remboursement
                        Conformément aux dispositions de l’article L.221-5 et suivants du Code de
                        la Consommation, le droit de rétractation ne peut être exercer pour :
                        - les contrats de fourniture de biens susceptibles de se détériorer ou de se
                        périmer rapidement,
                        - les biens descellés par l’acheteur après la livraison et qui ne peuvent
                        être renvoyés pour des raisons d’hygiène ou de protection de la santé.
                        Pour les autres produits, conformément aux dispositions de l’article L
                        221-1 du Code de la consommation, pour les contrats conclus hors
                        établissement (c’est-à-dire les contrats souscrits dans tout lieu où le
                        prestataire n’exerce pas son activité habituelle ou s’il l’exerce, si le client
                        a été sollicité dans un lieu différent, ou lors d’une excursion ayant pour
                        but ou effet de promouvoir les biens et/ou services du prestataire) entre
                        le vendeur et un acheteur particulier consommateur, l’acheteur dispose,
                        conformément à la loi, d’un délai de rétractation de 14 jours à compter
                        de la conclusion du contrat ou de la réception du produit pour exercer
                        son droit de rétractation auprès du vendeur et annuler sa commande,
                        sans avoir à justifier de motifs ni à payer de pénalité, à fin d’échange ou
                        de remboursement, sauf si l’exécution des prestations a commencé, avec
                        l’accord de l’Acheteur et est pleinement réalisée, avant la fin du délai de
                        rétractation.
                        Les produits doivent cependant être retournés dans leur emballage
                        d'origine et en parfait état dans les 15 jours suivant la notification au
                        vendeur de la décision de rétractation de l'acheteur.
                        Les retours sont à effectuer dans leur état d'origine et complets
                        (emballage, accessoires, notice...) permettant leur recommercialisation à
                        l'état neuf, accompagnés de la facture d'achat.
                        Les produits endommagés, salis ou incomplets ne sont pas repris.
                        Le droit de rétractation peut être exercé en ligne, à l'aide du formulaire
                        de rétractation disponible sur le site internet https://conserverie-
                        bertin.mdici.fr/. Dans ce cas, un accusé de réception sur un support
                        durable sera immédiatement communiqué à l'acheteur. Tout autre mode
                        de déclaration de rétractation est accepté. Il doit être dénué d'ambiguïté
                        et exprimer la volonté de se rétracter.
                        En cas d'exercice du droit de rétractation dans le délai susvisé, sont
                        remboursés le prix du ou des produit(s) acheté(s) et les frais de livraison
                        sont remboursés.
                        Les frais de retour sont à la charge de l'acheteur.
                        L'échange (sous réserve de disponibilité) ou le remboursement sera
                        effectué dans un délai de 14 jours à compter de la réception, par le
                        vendeur, des produits retournés par l'acheteur dans les conditions
                        prévues ci-dessus.
                        Conformément à l'article L. 242-4 du code de la consommation, lorsque le
                        professionnel n'a pas remboursé les sommes versées par le
                        consommateur, les sommes dues sont de plein droit majorées.
                        Le droit de rétractation peut être exercé à l’aide du formulaire type de
                        rétractation accompagnant les présentes conditions générales de vente,
                        auquel cas un accusé de réception sur un support durable sera
                        immédiatement communiqué à l’Acheteur par le vendeur, ou de toute
                        autre déclaration, dénuée d’ambiguïté, exprimant la volonté de se
                        rétracter.
                        Le droit de rétractation s’applique sous réserve des exceptions
                        mentionnées à l’article L.221-28 du code de la consommation.
                        Ce droit de rétractation ne s’applique ni à l’acheteur professionnel, ni à
                        l’acheteur collectivité. Enfin, ce droit ne s’applique pas si la réalisation de
                        la prestation intervient, en accord avec l’acheteur, avant l’expiration du
                        délai de 14 jours.</p>

                    <p className="mb-4">Article 12 - Force majeure
                        Toutes circonstances indépendantes de la volonté des parties empêchant
                        l'exécution dans des conditions normales de leurs obligations sont
                        considérées comme des causes d'exonération des obligations des parties
                        et entraînent leur suspension.
                        La partie qui invoque les circonstances visées ci-dessus doit avertir
                        immédiatement l'autre partie de leur survenance, ainsi que de leur
                        disparition.
                        Seront considérés comme cas de force majeure tous faits ou
                        circonstances irrésistibles, extérieurs aux parties, imprévisibles,
                        inévitables, indépendants de la volonté des parties et qui ne pourront
                        être empêchés par ces dernières, malgré tous les efforts raisonnablement
                        possibles. De façon expresse, sont considérés comme cas de force
                        majeure ou cas fortuits, outre ceux habituellement retenus par la
                        jurisprudence des cours et des tribunaux français : le blocage des moyens
                        CONDITIONS GENERALES DE VENTE
                        SOCIETE PECHERIE SEUDRE-ATLANTIQUE
                        SAS unipersonnelle au capital de 12 196 euros
                        Siège social : 1 bis rue Pierre Loti – 17390 LA TREMBLADE
                        384 683 967 RCS LA ROCHELLE
                        de transports ou d'approvisionnements, tremblements de terre,
                        incendies, tempêtes, inondations, foudre, l'arrêt des réseaux de
                        télécommunication ou difficultés propres aux réseaux de
                        télécommunication externes aux clients.
                        Les parties se rapprocheront pour examiner l'incidence de l'événement et
                        convenir des conditions dans lesquelles l'exécution du contrat sera
                        poursuivie. Si le cas de force majeure a une durée supérieure à trois mois,
                        les présentes conditions générales pourront être résiliées par la partie
                        lésée.</p>

                    <p className="mb-4">Article 13 - Non-validation partielle
                        Si une ou plusieurs stipulations des présentes conditions générales sont
                        tenues pour non valides ou déclarées telles en application d'une loi, d'un
                        règlement ou à la suite d'une décision définitive d'une juridiction
                        compétente, les autres stipulations garderont toute leur force et leur
                        portée.</p>

                    <p className="mb-4">Article 14 - Non-renonciation
                        Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par
                        l'autre partie à l'une quelconque des obligations visées dans les présentes
                        conditions générales ne saurait être interprété pour l'avenir comme une
                        renonciation à l'obligation en cause.</p>

                    <p className="mb-4">Article 15 - Propriété intellectuelle
                        Le contenu du site internet (documents techniques, dessins,
                        photographies, fiches produits etc...) demeurent la propriété exclusive de
                        la société PECHERIE SEUDRE ATLANTIQUE seule titulaire des droits de
                        propriété intellectuelle sur ces documents, et doivent lui être rendus à sa
                        demande.
                        Les acheteurs s'engagent à ne faire aucun usage de ces documents,
                        susceptible de porter atteinte aux droits de propriété industrielle ou
                        intellectuelle du vendeur et s'engagent à ne les divulguer à aucun tiers.
                        Toute reproduction totale ou partielle est strictement interdite et
                        susceptible de constituer un délit.</p>

                    <p className="mb-4">Article 16 – Loi applicable
                        Les présentes conditions générales sont soumises à l'application du droit
                        français, à l'exclusion des dispositions de la convention de Vienne. Il en
                        est ainsi pour les règles de fond comme pour les règles de forme. En cas
                        de litige ou de réclamation, l'acheteur s'adressera en priorité au vendeur
                        pour obtenir une solution amiable.</p>

                    <p className="mb-4">Article 17 - Langue du contrat
                        Les présentes conditions générales de vente sont rédigées en langue
                        française. Dans le cas où elles seraient traduites en une ou plusieurs
                        langues étrangères, seul le texte français ferait foi en cas de litige.</p>

                    <p className="mb-4">Article 18 - Médiation
                        L'acheteur peut recourir à une médiation conventionnelle, notamment
                        auprès de la Commission de la médiation de la consommation ou auprès
                        des instances de médiation sectorielles existantes, ou à tout mode
                        alternatif de règlement des différends (conciliation, par exemple) en cas
                        de contestation.
                        Conformément aux articles L.616-1 et R.616-1 du code de la
                        consommation, nous proposons un dispositif de médiation de la
                        consommation. L'entité de médiation retenue est : CNPM - MEDIATION
                        DE LA CONSOMMATION. En cas de litige, vous pouvez déposer votre
                        réclamation sur son site : https://cnpm-mediation-consommation.eu ou
                        par voie postale en écrivant à CNPM - MEDIATION - CONSOMMATION -
                        27 avenue de la libération - 42400 Saint-Chamond. En particulier,
                        l’acheteur consommateur doit justifier avoir préalablement tenté de
                        résoudre son litige directement auprès du prestataire par une
                        réclamation adressée par lettre recommandée avec accusé de réception.
                        L’Acheteur consommateur dispose d’un délai d’un an à compter de sa
                        réclamation écrite auprès du prestataire pour introduire sa demande
                        auprès du médiateur.</p>

                    <p className="mb-2">Article 19 - Traitement des données personnelles
                        Les données nominatives fournies par l'acheteur sont nécessaires au
                        traitement de sa commande et à l'établissement des factures.
                        Elles peuvent être communiquées aux partenaires du vendeur chargés de
                        l'exécution, du traitement, de la gestion et du paiement des commandes.
                        Le traitement des informations communiquées par l'intermédiaire du site
                        internet https://conserverie-bertin.mdici.fr a fait l'objet d'une déclaration
                        auprès de la CNIL.
                        L'acheteur dispose d’un droit d’accès, de rectification, de portabilité,
                        d’effacement de celles-ci ou d’une limitation du traitement. Vous pouvez
                        vous opposer au traitement des données vous concernant et disposez du
                        droit de retirer votre consentement à tout moment en vous adressant au
                        vendeur.</p>

                    <p className="mb-5">Vous disposez du droit d’introduire une réclamation auprès de
                        la CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07, site
                        Internet : www.cnil.fr
                        CONDITIONS GENERALES DE VENTE
                        SOCIETE PECHERIE SEUDRE-ATLANTIQUE
                        SAS unipersonnelle au capital de 12 196 euros
                        Siège social : 1 bis rue Pierre Loti – 17390 LA TREMBLADE
                        384 683 967 RCS LA ROCHELLE</p>

                    <h1 className="font-semibold mb-4">ANNEXES</h1>

                    <p className="mb-5">• Article L217-4
                        Le vendeur livre un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance.
                        Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à
                        sa charge par le contrat ou a été réalisée sous sa responsabilité.</p>

                    <p className="mb-5">• Article L217-5
                        Le bien est conforme au contrat :
                        1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :
                        - s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de
                        modèle ;
                        - s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou
                        par son représentant, notamment dans la publicité ou l'étiquetage ;
                        2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté
                        à la connaissance du vendeur et que ce dernier a accepté.</p>

                    <p className="mb-5">• Article L217-12
                        L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien.</p>

                    <p className="mb-5">• Article L217-16
                        Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l'acquisition ou de la réparation
                        d'un bien meuble, une remise en état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de la
                        garantie qui restait à courir.
                        Cette période court à compter de la demande d'intervention de l'acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise
                        à disposition est postérieure à la demande d'intervention.</p>

                    <p className="mb-5">• Article 1641
                        Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l'usage auquel on la destine, ou qui
                        diminuent tellement cet usage que l'acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus.</p>

                    <p>• Article 1648
                        L'action résultant des vices rédhibitoires doit être intentée par l'acquéreur dans un délai de deux ans à compter de la découverte du vice.
                        Dans le cas prévu par l'article 1642-1, l'action doit être introduite, à peine de forclusion, dans l'année qui suit la date à laquelle le vendeur peut être
                        déchargé des vices ou des défauts de conformité apparents.</p>
                </div>
            </div>


        </div>
    );
}

export default Cgv;
