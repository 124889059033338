import api from "../api/regions";

// GET ALL REGIONS
export const getAllRegions = async () => {
    const result = await api
        .getAllRegions();
    if (result) {
        return result;
    }
}

// GET REGION BY ID
export const getRegionById = async (regionId: string) => {
    const result = await api
        .getRegionById(regionId);
    if (result) {
        return result;
    }
}