import api from "../api/categories";

// GET ALL CATEGORIES
export const getAllCategories = async () => {

    return api
        .getAllCategories()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET ALL CATEGORIES FLAT
export const getAllCategoriesFlat = async () => {

    return api
        .getAllCategoriesFlat()
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};

// GET CATEGORY BY ID
export const getCategoryById = async (catId: string) => {

    return api
        .getCategoryById(catId)
        .then(async (result: any) => {
            if (result.data) {
                return result.data
            }
        })
};