import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";
import { BORDERS, COLORS } from "config/theme";

export interface ButtonPrimaryProps extends ButtonProps { }

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      style={{ backgroundColor: COLORS?.buttonPrimary, border: '1px', borderRadius: BORDERS?.buttonPrimary, color: "white" }}
      className={`disabled:opacity-70 hover:disabled:bg-opacity-70 hover:opacity-[.9] text-slate-50 dark:text-slate-800 shadow-xl ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
