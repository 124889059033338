import { apiClient } from './apiClient'

function getAllRegions() {
    return apiClient().get('region')
}

function getRegionById(regionId: string) {
    return apiClient().get('region/' + regionId)
}

export default {
    getAllRegions,
    getRegionById
}
