import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";

import Img1 from "images/ima_1.jpeg";
import Img2 from "images/ima_2.jpeg";
import Img3 from "images/ima_3.jpeg";
import Img4 from "images/ima_4.jpeg";
import Img6 from "images/ima_6.jpeg";
import Img8 from "images/ima_8.jpeg";
import Img9 from "images/ima_9.jpeg";
import Img10 from "images/ima_10.jpeg";
import Img11 from "images/ima_11.jpeg";

import LogoCouleur from "images/logo-couleur.png";

import FacebookIcon from "images/facebook-social-icon.png";
import InstagramIcon from "images/insta-social-icon.png";

import Rein from "images/rein.png";


const PageHome = () => {

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Conserverie Bertin</title>
        <meta name="description" content="Depuis 1969, La conserverie Bertin est une entreprise familiale. Soupe de poisson 100% artisanale, cuisinée en Charente Maritime avec des produits frais & locaux." />
      </Helmet>

      {/* CAROUSSEL  */}
      <SectionHero />
      {/* CAROUSSEL  */}

      {/* GALLERY */}
      <div className="bg-[#1a1819]">
        <div className="container flex justify-center">
          <div className="grid lg:grid-cols-3 xl:grid-cols-3 xs:grid-cols-1 sm:grid-cols-1 gap-3 mt-10 mb-10">
            <div className="relative overflow-hidden">
              <img className="w-full h-full transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img8} alt="Image 8" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-full transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img1} alt="Image 1" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-full transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img10} alt="Image 10" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-full transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img2} alt="Image 2" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-full transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img4} alt="Image 4" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-full transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img6} alt="Image 6" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-[300px] lg:h-[500px] object-cover transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img11} alt="Image 11" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-[300px] lg:h-[500px] object-cover transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img9} alt="Image 9" />
            </div>
            <div className="relative overflow-hidden">
              <img className="w-full h-[300px] lg:h-[500px] object-cover transition-transform duration-300 transform hover:scale-110 cursor-pointer" src={Img3} alt="Image 3" />
            </div>
          </div>
        </div>
      </div>
      {/* GALLERY */}

      {/* YOUTUBE PLAYER  */}
      <div className="bg-[#eee]">
        <div className={`container`}>
          <div className={`flow-root`}>
            <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-row mt-10 mb-10">
              <div className="flex justify-center w-full h-[465px]">
                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/jtzDaKN-IbQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* YOUTUBE PLAYER  */}

      {/* OUR SOCIAL  */}
      <div className="bg-white">
        <div className={`container`}>
          <div className="mt-10 mb-10 grid lg:grid-cols-2 place-items-center	 justify-center">
            <div className="flex flex-col  justify-center items-center">
              <img className="w-1/2 flex justify-center items-center" src={LogoCouleur} />
              <div className="mt-6">
                <hr />
                <div className="flex justify-center">
                  <h1 className="uppercase text-[#777] text-[30px] max-w-[70%] mt-5 leading-[40px] text-center">Rejoignez nous sur les réseaux sociaux</h1>
                </div>
                <div className="flex justify-center mt-3 mb-5">
                  <img onClick={() => window.open('https://www.facebook.com/conserveriebertin17')} className="cursor-pointer" src={FacebookIcon} />
                  <img onClick={() => window.open('https://www.instagram.com/conserverie.bertin17/')} className="cursor-pointer" src={InstagramIcon} />
                </div>
                <hr />
              </div>

            </div>
            <div>
              <iframe src="https://www.facebook.com/plugins/page.php?href=conserveriebertin17&tabs=timeline&width=340&height=550&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="340" height="550" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* OUR SOCIAL  */}

      {/* BEFORE FOOTER  */}
      <div className="bg-[#1a1819]">
        <div className={`container`}>
          <div className="py-10 flex justify-center">
            <img src={Rein} />
          </div>
        </div>
      </div>
      {/* BEFORE FOOTER  */}

    </div>
  );
}

export default PageHome;
