import auth from "./auth/auth";
import user from './user/user'
import cart from './cart/cart'
import wishlist from './wishlist/wishlist'
import language from './language/language'

const rootReducers = {
  auth,
  user,
  cart,
  wishlist,
  language
};

export default rootReducers;
