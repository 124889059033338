import { Helmet } from "react-helmet";
import Img1 from "images/img97.png";

const AujourdhuiCestLangoustine = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Aujourdhui c'est langoustine | Conserverie Bertin</title>
            </Helmet>


            {/* OUR TEAMS  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8 mb-14">

                            <div className="flex justify-center flex flex-col mb-14 mt-10">
                                <h1 className="uppercase text-[45px] text-[#777] font-julius leading-[55px] font-[600] text-center lg:whitespace-nowrap md:whitespace-nowrap xl:whitespace-nowrap uppercase">Aujourdhui c'est langoustine</h1>
                                <div className="flex justify-center mt-4">
                                    <img className="w-1/4" src={Img1} />
                                </div>
                            </div>


                            <div className="max-w-5xl text-[14px] font-normal ">
                                <h1 className="mb-4">Ces mêmes langoustines qui servent à la préparation de notre soupe "La Trembladaise"...Et juste pour vous, un petit conseil pour bien réussir la cuisson de vos langoustines (conseil pour 1,5/2kg de langoustines)</h1>
                                <ul className="list-disc ml-8 font-semibold">
                                    <li>Choisissez une grande casserole et la remplir au ¾ d’eau.</li>
                                    <li>Commencez à épicer votre court-bouillon en mettant du gros sel, du poivre noir moulu et en grain ainsi qu’environ 20 cl de vin blanc.</li>
                                    <li>Faites chauffer l’eau à feu doux afin de gouter si le mélange de vos épices vous convient.</li>
                                    <li>Mettez ensuite ½ oignon, 2 à 3 clous de girofle piqués dans l’oignon, 3 à 4 feuilles de laurier séché et une pincée de persil ciselé.</li>
                                    <li>Ajoutez un filet d’huile, vos langoustines se décortiqueront sans difficulté une fois cuites.</li>
                                </ul>
                                <p className="leading-[28px] mt-4">Lorsque le court-bouillon bout, ajoutez vos langoustines. Au moment où votre préparation recommence à bouillir comptez entre 3 et 6 minutes de cuisson selon la grosseur des langoustines.</p>
                                <p className="leading-[28px] mt-4">Si elles sont petites, faites-les cuire 3 minutes. Si elles sont de taille moyenne laissez-les entre 3 à 4 minutes. Pour les plus grosses n’hésitez pas à les laisser entre 5 à 6 minutes.
                                <br />Lorsqu’elles sont cuites, rincez-les à l’eau claire et froide puis égouttez-les.</p>

                                <p className="leading-[28px] mt-4">Maintenant vos langoustines sont prêtes à être dégustées !</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AujourdhuiCestLangoustine;
