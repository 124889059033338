import { User } from 'models/User'
import { apiClient } from './apiClient'

function login({ password, email }: { password: string, email: string }) {
    return apiClient().post('/user/login', { password, email })
}

function logout() {
    return apiClient().post('/user/logout')
}

function register(user: User) {
    return apiClient().post('/user/register', user)
}

function forgotPasswordMail(email: string) {
    return apiClient().post('/user/resetPass', { email: email })
}

function resetPasswordMail(password: string, id: string) {
    return apiClient().post('/user/resetPass', { newPass: password, resetId: id })
}

export default {
    login,
    logout,
    register,
    forgotPasswordMail,
    resetPasswordMail
}
