import { User } from 'models/User'
import { apiClient } from './apiClient'

function getUserInfos() {
    return apiClient().get('/user/')
}
function updateUserInfos(updatedUser: User) {
    return apiClient().patch('/user/', updatedUser)
}

function changePassword(currPass: string, newPass: string) {
    return apiClient().patch('user/changePass', { currPass: currPass, newPass: newPass })
}

function addShippingAddress(address: object) {
    return apiClient().post('user/address', address)
}

function getAllShippingAddress() {
    return apiClient().get('user/address')
}

function deleteShippingAddress(id: string) {
    return apiClient().delete('user/address/' + id)
}

function editShippingAddress(address: object) {
    return apiClient().patch('user/address/', address)
}

function getShippingAddressById(shippingAdressId: string) {
    return apiClient().get('user/address/' + shippingAdressId)
}

export default {
    getUserInfos,
    changePassword,
    updateUserInfos,
    addShippingAddress,
    getAllShippingAddress,
    deleteShippingAddress,
    editShippingAddress,
    getShippingAddressById
}