import api from "../api/cart";
import { CREATE_CART_ANONYMOUS, GET_CART, GET_ANONYMOUS_CART } from "./types";

// ADD PRODUCT IN CART
export const addProductInCart = (itemId: any, quantity: number): any =>

    async (dispatch: any) => {
        return api
            .addProductInCart(itemId, quantity)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_CART, payload: result.data })
                    return result.data
                }
            })
    };

// PATCH CART
export const patchCart = (data: any): any =>

    async (dispatch: any) => {
        return api
            .patchCart(data)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_CART, payload: result.data })
                    return result.data
                }
            })
    };



// GET CART
export const getProductInCart = (): any =>

    async (dispatch: any) => {
        return api
            .getProductInCart()
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_CART, payload: result.data })
                    return result.data
                }
            })
    };

// DELETE PRODUCT IN CART
export const deleteProductInCart = (itemId: string): any =>

    async (dispatch: any) => {
        return api
            .deleteProductInCart(itemId)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_CART, payload: result.data })
                    return result.data
                }
            })
    };

// EDIT PRODUCT IN CART
export const editProductInCart = (itemId: string, newQuantity: number): any =>

    async (dispatch: any) => {
        return api
            .editProductInCart(itemId, newQuantity)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_CART, payload: result.data })
                    return result.data
                }
            })
    };

// CREATE ANONYMOUS CART
export const createAnonymousCart = (): any =>

    async (dispatch: any) => {
        return api
            .createAnonymousCart()
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: CREATE_CART_ANONYMOUS, payload: result.data })
                    return result.data
                }
            })
    };

// ADD PRODUCT INTO ANONYMOUS CART
export const addProductToAnonymousCart = (anonymousCartId: string, itemId: string, quantity: number): any =>

    async (dispatch: any) => {
        return api
            .addProductToAnonymousCart(anonymousCartId, itemId, quantity)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_ANONYMOUS_CART, payload: result.data })
                    return result.data
                }
            })
    };

// GET ANONYMOUS CART
export const getAnonymousCart = (itemId: string): any =>
    async (dispatch: any) => {
        return api
            .getAnonymousCart(itemId)
            .then(async (result: any) => {
                if (result.data) {
                    dispatch({ type: GET_ANONYMOUS_CART, payload: result.data })
                    return result.data
                }
            })
    };

// EDIT PRODUCT IN ANONYMOUS CART
export const editProductInAnonymousCart = (anonymousCartId: string, itemId: string, newQuantity: number): any =>

    async (dispatch: any) => {
        return api
            .editProductInAnonymousCart(anonymousCartId, itemId, newQuantity)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_ANONYMOUS_CART, payload: result.data })
                    return result.data
                }
            })
    };


// DELETE PRODUCT IN ANONYMOUS CART
export const deleteProductInAnonymousCart = (anonymousCartId: string, itemId: string): any =>

    async (dispatch: any) => {
        return api
            .deleteProductInAnonymousCart(anonymousCartId, itemId)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_ANONYMOUS_CART, payload: result.data })
                    return result.data
                }
            })
    };

// MERGE ANONYMOUS CART
export const mergeAnonymousCart = (anonymousCartId: string): any =>

    async (dispatch: any) => {
        return api
            .mergeAnonymousCart(anonymousCartId)
            .then(async (result: any) => {

                if (result.data) {
                    dispatch({ type: GET_CART, payload: result.data })
                    return result.data
                }
            })
    };


// ADD DISCOUNT CODE
export const addDiscountCode = (discountCode: string): any => {
    return api
        .addDiscountCode(discountCode)
        .then(async (result: any) => {

            if (result.data) {
                return result.data
            }
        })
};

// DELETE DISCOUNT CODE
export const deleteDiscountCode = (): any => {
    return api
        .deleteDiscountCode()
        .then(async (result: any) => {

            if (result.data) {
                return result.data
            }
        })
};

// PATCH CART
export const patchCartAnonymousCart = (anonymousCartId: any, data: any): any =>

    async (dispatch: any) => {
        return api
            .patchCartAnonymousCart(anonymousCartId, data)
            .then(async (result: any) => {

                if (result.data) {
                    return result.data
                }
            })
    };