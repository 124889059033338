import { Helmet } from "react-helmet";

import bck from "images/bck-contact.jpeg";

import locateIcon from "images/locate.png";
import phoneIcon from "images/phone.png";
import emailIcon from "images/email.png";
import facebookIcon from "images/facebook.png";
import instagramIcon from "images/instagram.png";

const Contact = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Contact | Conserverie Bertin</title>
            </Helmet>


            <div>
                <div className="relative">
                    <img src={bck} className="w-full h-[450px] lg:h-auto md:h-auto xl:h-auto" />

                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:w-full">
                        <h1 className="mb-6 uppercase font-julius text-[white] font-[600] uppercase lg:text-[46px] sm:mb-3 md:text-[23px] xs:text-[46px] sm:text-[26px] sm:leading-[30px] xs:leading-[50px] text-center lg:leading-[50px]">
                            Contactez-nous
                        </h1>
                        <p className="text-[white] font-semibold text-center">Vous avez une question, vous avez un besoin professionnel ou vous voulez nous retrouver à la conserverie ? </p>


                        <div className="flex flex-col text-center items-center mt-6 text-[white] font-semibold">


                            <div className="flex flex-row">
                                <img className="h-4 w-4 relative top-[.2rem] mr-1" src={locateIcon} />
                                <h1>1bis, rue Pierre Loti - 17390 La Tremblade</h1>
                            </div>

                            <div className="flex flex-row mt-2">
                                <img className="h-4 w-4 relative top-[.2rem] mr-1" src={phoneIcon} />
                                <h1>Service client particulier: 05 46 36 09 99</h1>
                            </div>



                            <div className="flex flex-row mt-2">
                                <img className="h-4 w-4 relative top-[.2rem] mr-1" src={emailIcon} />
                                <a href="mailto:conserveriebertin@gmail.com" className="underline hover:text-[#f90]">conserveriebertin@gmail.com</a>
                            </div>


                            <div className="flex flex-row mt-2">
                                <img className="h-4 w-4 relative top-[.2rem] mr-1" src={facebookIcon} />
                                <a onClick={() => window.open('https://www.facebook.com/conserveriebertin17')} className="cursor-pointer underline hover:text-[#f90]">conserveriebertin17</a>
                            </div>


                            <div className="flex flex-row mt-2">
                                <img className="h-4 w-4 relative top-[.2rem] mr-1" src={instagramIcon} />
                                <a onClick={() => window.open('https://www.instagram.com/conserverie.bertin17/')} className="cursor-pointer underline hover:text-[#f90]">conserverie.bertin17</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            {/* BEFORE FOOTER  */}
            <div className="bg-[#1a1819]">
                <div className={`container h-[590px]`}>
                    <div className="py-10 flex justify-center w-full h-full">
                        <iframe width={'100%'} height={'500px'} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.9170173909406!2d-1.145952023030171!3d45.77285327108075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480164edb6ebffff%3A0x43f787aa02c1adfd!2s1bis%20Rue%20Pierre%20Loti%2C%2017390%20La%20Tremblade!5e0!3m2!1sfr!2sfr!4v1689845088397!5m2!1sfr!2sfr"></iframe>
                    </div>
                </div>
            </div>
            {/* BEFORE FOOTER  */}

        </div>
    );
}

export default Contact;
