export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS";
export const USER_INFO_FAIL = "USER_INFO_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const LOGOUT = "LOGOUT"
export const GET_WISHLIST = "GET_WISHLIST"
export const GET_CART = "GET_CART"
export const CREATE_CART_ANONYMOUS = "CREATE_CART_ANONYMOUS"
export const GET_ANONYMOUS_CART = "GET_ANONYMOUS_CART"
export const SUCCESS_ADD_SHIPPING_ADDRESS = "SUCCESS_ADD_SHIPPING_ADDRESS"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const GET_LANGUAGE = "GET_LANGUAGE"
