import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";
import { BORDERS, COLORS } from "config/theme";

export interface ButtonSecondaryProps extends ButtonProps { }

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  className = " border border-slate-300 dark:border-slate-700 ",
  ...args
}) => {
  return (
    <Button
      style={{ backgroundColor: COLORS?.buttonSecondary, border: '1px', borderRadius: BORDERS?.buttonSecondary }}
      className={`ttnc-ButtonSecondary bg-white text-[white] ${className}`}
      {...args}
    />
  );
};

export default ButtonSecondary;
