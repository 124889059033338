import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";
import { BORDERS, COLORS } from "config/theme";

export interface ButtonThirdProps extends ButtonProps {}

const ButtonThird: React.FC<ButtonThirdProps> = ({
  className = "border border-neutral-200",
  ...args
}) => {
  return <Button style={{ backgroundColor: COLORS?.buttonThird, border: '1px', borderRadius: BORDERS?.buttonThird, color: "white" }}
  className={`ttnc-ButtonThird ${className} text-[white]`} {...args} />;
};

export default ButtonThird;
