import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import AutoComplete from "react-google-autocomplete";
import { GOOGLE_MAP_API } from "../../config/key";
import { useEffect, useState } from "react";
import { addShippingAddress } from "actions/user";
import { useDispatch } from "react-redux";
import { NotificationManager } from 'react-notifications';
import { Link, useHistory } from "react-router-dom";

const AddShippingAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [shippingAddress, setShippingAddress]: any = useState([])
  const [address, setAddress]: any = useState([])
  const [description, setDescription]: any = useState()
  const [deliveryInformation, setDeliveryInformation]: any = useState()
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (shippingAddress) {
      let result: any = {}

      shippingAddress?.address_components?.map((it: any) => {
        if (it.types[0] === "country") {
          result['country'] = it.long_name
        }
        if (it.types[0] == "locality") {
          result['city'] = it.long_name
        }
        if (it.types[0] == "postal_code") {
          result['postalCode'] = it.long_name
        }

        result['address'] = shippingAddress?.formatted_address?.split(',')[0]

        result['defaut'] = checked ? true : false

        result['description'] = description
        result['deliveryInformation'] = deliveryInformation

        if (shippingAddress?.geometry) {
          result['lat'] = shippingAddress?.geometry?.location.lat();
          result['long'] = shippingAddress?.geometry?.location.lng();
        }

      })
      setAddress(result)
    }
  }, [shippingAddress, checked])

  const handleCreateShippingAdress = async () => {
    if (Object.keys(address).length !== 0) {
      dispatch(addShippingAddress(address))
        .then((res: any) => {
          NotificationManager.success("Votre adresse de livraison a été ajouter avec succès !", '');
          history.push("/shipping-address")
        })
    }
  }

  return (

    <div>
      <div className="container py-14">
        <div className="flow-root">
          <div className="flex justify-center flex-col lg:px-28">
            <div className="flex flex-row gap-1 font-[500] text-[13px]">
              <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
              <span>{">"}</span>
              <a href="">Ajouter une adresse de livraison</a>
            </div>

            <div className="mt-16 mb-16">
              <div className="flex w-full">
                <div className="text-2xl  sm:text-3xl mb-8 font-semibold flex flex-row">
                  <Link to="/customer/overview" className="w-10 h-7 relative top-[.1rem] flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                    <span className="text-[20px] font-[500]">{"<"}</span>
                  </Link>
                  <h1 className="ml-4">Ajouter une adresse de livraison</h1>
                </div>
              </div>

              <div className="flex flex-row mt-10">
                <div className="mt-4 w-full mr-2">
                  <Label>Description</Label>
                  <Input type="text" placeholder="Ma maison, mon bureau.." onChange={(e) => setDescription(e.target.value)} className="mt-1.5" />
                </div>
                <div className="mt-4 w-full mr-2">
                  <Label>Information de livraison</Label>
                  <Input type="text" placeholder="Livrer le colis au pied de la porte" onChange={(e) => setDeliveryInformation(e.target.value)} className="mt-1.5" />
                </div>
              </div>

              <div className="mt-4">
                <Label>Adresse</Label>
                <AutoComplete
                  apiKey={GOOGLE_MAP_API}
                  onPlaceSelected={(place: any) => {
                    setShippingAddress(place)
                  }}
                  placeholder="Saisissez une adresse"
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  className='block w-full border-[1px] border-neutral-200  focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded text-sm font-normal h-11 px-4 py-3 mt-1.5'
                />


                {shippingAddress !== 0 &&
                  <>
                    {Object.keys(address).length !== 0 &&
                      <>
                        <div className="flex flex-row">
                          <div className="mt-4 w-full mr-2">
                            <Label>Code postal</Label>
                            <Input type="text" className="mt-1.5" defaultValue={address['postalCode']} disabled />
                          </div>

                          <div className="mt-4 w-full ml-2">
                            <Label>Ville</Label>
                            <Input type="text" className="mt-1.5" defaultValue={address['city']} disabled />
                          </div>
                        </div>

                        <div className="mt-4 w-full mr-2">
                          <Label>Contry</Label>
                          <Input type="text" className="mt-1.5" defaultValue={address['country']} disabled />
                        </div>
                      </>
                    }

                  </>
                }

                <div className="flex flex-row mt-3">
                  <div className="">
                    <input
                      className="rounded"
                      type="checkbox"
                      checked={checked}
                      onChange={() => handleChange()} />
                  </div>
                  <span className="ml-2 mt-[.1rem]">Définir en tant qu'adresse de livraison principale</span>
                </div>

                <div className="mt-8">
                  <ButtonPrimary onClick={() => handleCreateShippingAdress()}>Ajouter</ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddShippingAddress;
