import { GET_CART, CREATE_CART_ANONYMOUS, GET_ANONYMOUS_CART } from '../../actions/types'

const defaultState = {}

export default (state = defaultState, action: any) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case GET_CART:
            return { ...payload };
        case CREATE_CART_ANONYMOUS:
            return { ...payload }
        case GET_ANONYMOUS_CART:
            return { ...state, ...payload }
        default:
            return state
    }
}