import { apiClient } from './apiClient'

function getStripePublicKey() {
    return apiClient().get('/stripe/public')
}

function getStripeClientSecret() {
    return apiClient().get("cart/paymentLink")
}

export default {
    getStripePublicKey,
    getStripeClientSecret
}
