import React, { useEffect, useState } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { Disclosure } from "@headlessui/react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
import { NAVIGATION_DEMO_2 } from "data/navigation";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { getAllCategories } from "actions/categories";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "images/logo-couleur.png";
import { useSelector } from "react-redux";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const history = useHistory();
  const { auth }: any = useSelector(state => state)

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getAllCategories().then((result: any) => {
      setCategories(result);
    })
  }, [])

  const handleRedirect = (item: any) => {
    if (item) {
      onClickClose()
      history.push("/product-detail/" + item?.slug);
    }
  }

  const _renderMenuChild = (
    item: any,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.subCat?.map((i: any, index: number) => (
          <Disclosure key={i?._id} as="li" defaultOpen={true}>
            <NavLink
              exact
              strict
              to={{
                pathname: undefined || undefined,
              }}
              className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
              activeClassName="text-secondary"
            >
              <span
                className={`py-2.5 ${!i.subCat ? "block w-full" : ""}`}
                onClick={() => handleRedirect(i)}
              >
                {i.name}
              </span>
              {i.subCat && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    {Object.keys(i?.subCat).length !== 0 &&
                      <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-slate-500"
                        aria-hidden="true"
                      />
                    }
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.subCat && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: any, index: number) => {
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-slate-900 dark:text-white"
        defaultOpen={true}
      >
        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
          to={{
            pathname: '/products/',
            state: { id: item?._id } || undefined,
          }}
          activeClassName="text-secondary"
        >
          <span
            className={!item.subCat ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.subCat && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.subCat && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Positionne l'utilisateur en haut de la page à chaque changement de route
  }, [history.location.pathname]);

  const handleRedirectLogin = () => {
    onClickClose()
  }

  return (
    <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
      <div className="py-2 px-5">
        <div className="flex">
          <img onClick={() => { history.push({ pathname: '/' }); onClickClose() }} src={Logo} className="w-1/3 cursor-pointer" />
        </div>
        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        {/* <div className="mt-5">{renderSearchForm()}</div> */}
      </div>

      <ul className="flex flex-col py-6 px-2 space-y-1">

        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
          onClick={() => onClickClose()}
          to={{
            pathname: "/pages/conserverie-bertin"
          }}
          activeClassName="text-secondary"
        >
          CONSERVERIE BERTIN
        </NavLink>

        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
          onClick={() => onClickClose()}
          to={{
            pathname: "/pages/notre-gamme-de-soupe"
          }}
          activeClassName="text-secondary"
        >
          Notre gamme
        </NavLink>

        {categories.map(_renderItem)}

        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
          onClick={() => onClickClose()}
          to={{
            pathname: "/pages/au-fil-des-jours"
          }}
          activeClassName="text-secondary"
        >
          Au fils des jours
        </NavLink>

        <NavLink
          exact
          strict
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
          onClick={() => onClickClose()}
          to={{
            pathname: "/contact"
          }}
          activeClassName="text-secondary"
        >
          Nous contacter
        </NavLink>
      </ul>


      <div className="flex items-center py-6 px-6 space-x-2">
        {!auth?.user &&        
          <Link
            className=""
            onClick={() => handleRedirectLogin()}
            to={{ pathname: "/login" }}>
            <ButtonPrimary>Se connecter</ButtonPrimary>
          </Link>
        }
      </div>


    </div>
  );
};

export default NavMobile;
