
import { useEffect, useState } from "react";
import { logout } from "actions/auth";
import { getUserInfos } from "actions/user";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Overview = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [user, setUser]: any = useState({});

    useEffect(() => {
        const fetch = async () => {
            await dispatch(getUserInfos()).then((response: any) => {
                setUser({ ...user, ...response });
            })
        };

        fetch();
    }, []);

    const handleLogout = async () => {
        await dispatch(logout());
        history.replace('/');
    }

    return (
        <div>
            <Helmet>
                <title>Information sur le compte | Conserverie Bertin</title>
            </Helmet>

            <div className="">
                <div className={`container py-14`}>
                    <div className={`flow-root`}>
                        <div className="flex lg:px-28 font-semibold justify-center flex-col">

                            <div className="flex flex-row gap-1 font-[500] text-[13px]">
                                <a href="/" className="text-primary-6000 hover:underline">Accueil</a>
                                <span>{">"}</span>
                                <a href="">Information sur le compte</a>
                            </div>

                            <div className="py-16">
                                <div className="mb-10">
                                    <h1 className="text-[17px]">Bonjour {user?.name + " " + user?.lastname}</h1>
                                    <h2 className="text-[14px] text-slate-400 relative top-[-10px] mt-3">Inscrit depuis le 15 juin 2023</h2>
                                </div>
                                <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-3">
                                    <div onClick={() => history.push("/account")} className={`shadow-4xl font-medium p-4 flex justify-center text-[black] bg-[white] rounded items-center h-[140px] text-[white] text-[16px] hover:bg-[#F90] hover:text-[white] cursor-pointer transition-all duration-300`}>
                                        <div>
                                            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <span className="mt-2">Mon profil</span>
                                    </div>
                                    <div onClick={() => history.push("/account-my-order")} className="shadow-4xl font-medium p-4 flex justify-center text-[black] bg-[white] rounded items-center h-[140px] text-[white] text-[16px] hover:bg-[#F90] hover:text-[white] cursor-pointer transition-all duration-300">
                                        <div>
                                            <svg width="30" height="30" viewBox="0 0 24 24" fill="none">
                                                <path d="M8 12.2H15" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M8 16.2H12.38" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M16 4.02002C19.33 4.20002 21 5.43002 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44002 4.67 4.20002 8 4.02002" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <span className="mt-2">Mes commandes</span>
                                    </div>
                                    <div onClick={() => history.push("/shipping-address")} className="shadow-4xl text-center font-medium p-4 flex justify-center text-[black] bg-[white] rounded items-center h-[140px] text-[white] text-[16px] hover:bg-[#F90] hover:text-[white] cursor-pointer transition-all duration-300">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 64 64" strokeWidth="3" stroke="currentColor" fill="none">
                                                <path d="M19.55,43.93H38.1a2,2,0,0,0,2-2V16.28a2,2,0,0,0-2-2H8.68a2,2,0,0,0-2,2V41.91a2,2,0,0,0,2,2h2.6" />
                                                <path d="M44.33,43.93H40.19V23.23H50a1,1,0,0,1,.76.35l6.3,7.55a1,1,0,0,1,.23.64V43.93H53" />
                                                <ellipse cx="15.53" cy="45.23" rx="4.44" ry="4.5" />
                                                <ellipse cx="48.58" cy="45.23" rx="4.44" ry="4.5" />
                                            </svg>
                                        </div>
                                        <span className="mt-2">Mes adresses de livraison</span>
                                    </div>
                                    <div onClick={() => history.push("/account-savelists")} className="shadow-4xl font-medium p-4 flex justify-center text-[black] bg-[white] rounded items-center h-[140px] text-[white] text-[16px] hover:bg-[#F90] hover:text-[white] cursor-pointer transition-all duration-300">
                                        <div>
                                            <svg width="30" height="30" viewBox="0 0 24 24" fill="none"><path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                        </div>
                                        <span className="mt-2">Mes favoris</span>
                                    </div>
                                    <div onClick={() => history.push("/account-change-password")} className="shadow-4xl font-medium p-4 flex justify-center text-[black] bg-[white] rounded items-center h-[140px] text-[white] text-[16px] hover:bg-[#F90] hover:text-[white] cursor-pointer transition-all duration-300">
                                        <div>
                                            <svg width="30" height="30" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                                <path fill="currentColor" d="M25 12h-1v-3.816c0-4.589-3.32-8.184-8.037-8.184-4.736 0-7.963 3.671-7.963 8.184v3.816h-1c-2.206 0-4 1.794-4 4v12c0 2.206 1.794 4 4 4h18c2.206 0 4-1.794 4-4v-12c0-2.206-1.794-4-4-4zM10 8.184c0-3.409 2.33-6.184 5.963-6.184 3.596 0 6.037 2.716 6.037 6.184v3.816h-12v-3.816zM27 28c0 1.102-0.898 2-2 2h-18c-1.103 0-2-0.898-2-2v-12c0-1.102 0.897-2 2-2h18c1.102 0 2 0.898 2 2v12zM16 18c-1.104 0-2 0.895-2 2 0 0.738 0.405 1.376 1 1.723v3.277c0 0.552 0.448 1 1 1s1-0.448 1-1v-3.277c0.595-0.346 1-0.985 1-1.723 0-1.105-0.895-2-2-2z" />
                                            </svg>
                                        </div>
                                        <span className="mt-2">Modifier mot de passe</span>
                                    </div>

                                    <div onClick={() => handleLogout()} className="shadow-4xl font-medium p-4 flex justify-center text-[black] bg-[white] rounded items-center h-[140px] text-[white] text-[16px] hover:bg-[#F90] hover:text-[white] cursor-pointer transition-all duration-300">
                                        <div>
                                            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M15 12H3.62" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                <path d="M5.85 8.6499L2.5 11.9999L5.85 15.3499" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </div>
                                        <span className="mt-2">Déconnexion</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
