import api from "../api/wishlist";
import { GET_WISHLIST } from "./types";

// ADD PRODUCT IN WISHLIST
export const addProductInWishlist = (itemId: string): any =>

    async (dispatch: any) => {
        return api
            .addProductInWishlist(itemId)
            .then(async (result: any) => {

                if (result) {
                    dispatch({
                        type: GET_WISHLIST,
                        payload: result.data
                    });

                    return result.data
                }
            })
    };

// REMOVE PRODUCT IN WISHLIST
export const removeProductInWishlist = (itemId: string): any =>

    async (dispatch: any) => {
        return api
            .removeProductInWishlist(itemId)
            .then(async (result: any) => {

                if (result) {
                    dispatch({
                        type: GET_WISHLIST,
                        payload: result.data
                    });

                    return result.data
                }
            })
    };


// REMOVE PRODUCT IN WISHLIST
export const getAllProductsInWishlist = (): any =>
    async (dispatch: any) => {
        return api
            .getAllProductsInWishlist()
            .then(async (result: any) => {
                if (result) {
                    dispatch({
                        type: GET_WISHLIST,
                        payload: result.data
                    });

                    return result.data
                }
            })
            .catch((e) => {

            })
    };


    