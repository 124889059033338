import { useSelector } from "react-redux";
import MainNav2Logged from "components/Header/MainNavLogged";
import MainNav1 from "components/Header/MainNav";

const SiteHeader = () => {
  const { auth }: any = useSelector(state => state);

  return (
    <div className="nc-HeaderLogged sticky top-0 w-full z-40 ">
      {auth?.token ? <MainNav2Logged /> : <MainNav1 />}
    </div>
  )
};

export default SiteHeader;
