import { useEffect } from "react";
import { RouterProps, withRouter } from "react-router-dom";
import { Buffer } from 'buffer';
import { logout } from "actions/auth";
import { useDispatch } from "react-redux";

export interface OnChangeRouteProps {
  history: RouterProps["history"];
}

const OnChangeRoute: React.FC<OnChangeRouteProps> = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const unlisten = history.listen(async () => {

      const refreshToken = localStorage.getItem('token');

      if (refreshToken) {
        const payloadBase64 = refreshToken.split('.')[1];
        const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
        const decoded = JSON.parse(decodedJson)

        try {
          if (decoded.exp < (new Date().getTime() + 1) / 1000) {
            await dispatch(logout());
          }
        }
        catch {
          console.log('err')
        }
      }
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default withRouter(OnChangeRoute);
