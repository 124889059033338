import React from "react";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import mastercardImg from "images/mastercard.svg";
import visaImg from "images/visa.svg";
import stripeImg from "images/stripe.svg";
import { COLORS } from "config/theme";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Informations entreprise",
    menus: [
      { href: "#", label: "1 bis Rue Pierre Loti, 17390 La Tremblade" },
      { href: "#", label: "Service client particulier: 05 46 36 09 99" },
      { href: "mailto:conserveriebertin@gmail.com", label: "conserveriebertin@gmail.com" },
    ],
  },
  {
    id: "1",
    title: "Nos produits",
    menus: [
      { href: "/pages/notre-gamme-de-soupe", label: "Soupe poissons et crabes stérilisée" },
      { href: "/pages/notre-gamme-de-soupe", label: "Soupe de poissons et moules stérilisée" },
      { href: "/pages/notre-gamme-de-soupe", label: "Le velouté trembladais" },
      { href: "/pages/notre-gamme-de-soupe", label: "La trembladaise soupe de poissons et langoustines stérilisée" },
    ],
  },
  {
    id: "4",
    title: "Légal / Contact",
    menus: [
      { href: "/cgv", label: "CGV" },
      { href: "/mentions-legales", label: "Mentions légales" },
      { href: "/contact", label: "Nous contacter" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="">
          {menu.menus.map((item, index) => (
            <li key={index} className="xs:mt-2 lg:mt-1">
              <a
                key={index}
                className={`font-[400] dark:text-neutral-300 ${item?.label === "conserveriebertin@gmail.com" && "underline"}`}
                href={item.href}
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>

      <div className={`nc-Footer relative py-10 lg:pb-24`} style={{ backgroundColor: COLORS.footer, color: COLORS?.footerText }}>


        <div className="container flex lg:flex-row xs:flex-col justify-between">
          {widgetMenus.map(renderWidgetMenuItem)}

          <div className="">
            <h2 className="font-semibold xs:mb-3 xs:mt-6 lg:mb-5 font-[700] dark:text-neutral-200">
              Suivez-nous
            </h2>
            <div className="col-span-2 flex items-center md:col-span-3">
              <SocialsList1 className="flex items-center lg:flex-col lg:space-y-3 xs:space-x-1 lg:items-start" />
            </div>
          </div>
        </div>

        <div className="container mt-[35px]">

          <div className="border-t border-[#BFC6DB] ">

            <div>
              <h1 className="text-[13px] mt-[20px] text-center w-full">
                Site développé par <a target="_blank" className="underline hover:text-[#f90]" href="https://milango.io/">Milango</a>
              </h1>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
