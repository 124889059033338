import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Img1 from "images/img99.png";
import Img2 from "images/img98.png";
import Img3 from "images/img97.png";
import ButtonSecondary from "shared/Button/ButtonSecondary";


const AuFilDesJours = () => {

    return (
        <div className="nc-PageHome relative overflow-hidden">
            <Helmet>
                <title>Au fils des jours | Conserverie Bertin</title>
            </Helmet>


            {/* OUR TEAMS  */}
            <div className="bg-[#eee]">
                <div className={`container`}>
                    <div className={`flow-root`}>
                        <div className="flex text-3xl lg:px-36 font-semibold justify-center flex-col py-8">

                            <div className="flex justify-center mb-14 mt-10">
                                <h1 className="uppercase text-[45px] text-[#777] font-julius leading-[55px] font-[600] text-center lg:whitespace-nowrap md:whitespace-nowrap xl:whitespace-nowrap">AU FIL DES JOURS</h1>
                            </div>


                            <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 gap-8 w-full mb-14">

                                <div className="text-center">
                                    <img src={Img1} />

                                    <a className="text-[#f90] text-[18px] cursor-pointer">RECETTE AUTOMNE 2021</a>
                                    <h1 className="text-[16px] font-normal ml-3">Publié le 24/02/2022</h1>

                                    <p className="text-[16px] font-normal leading-[30px] mt-3 mb-2">Dans un plat a bord haut, verser la moitié de la soupe froide, rajoutez le parmesan, l’œuf, le sel et le poivre puis mélanger à la fourchette afin d’homogénéiser la préparation...</p>


                                    <Link to={{ pathname: "/pages/recette-automne-2021" }}>
                                        <ButtonSecondary className="">Lire la suite</ButtonSecondary>
                                    </Link>
                                </div>

                                <div className="text-center">
                                    <img src={Img2} />

                                    <a className="text-[#f90] text-[18px] cursor-pointer">RECETTE ETE 2021</a>
                                    <h1 className="text-[16px] font-normal ml-3">Publié le 24/02/2022</h1>

                                    <p className="text-[16px] font-normal leading-[30px] mt-3 mb-2">Dans un casserole faire chauffer la soupe. Dans un grand récipient, faire revenir les échalotes hachées dans l’huile d’olive puis verser les moules, rajouter le vin blanc, le persil puis couvrir avec un couvercle...</p>


                                    <Link to={{ pathname: "/pages/recette-ete-2021" }}>
                                        <ButtonSecondary className="">Lire la suite</ButtonSecondary>
                                    </Link>
                                </div>

                                <div className="text-center">
                                    <img src={Img3} />

                                    <a className="text-[#f90] text-[18px] cursor-pointer">AUJOURD'HUI C'EST LANGOUSTINE !</a>
                                    <h1 className="text-[16px] font-normal ml-3">Publié le 23/02/2022</h1>

                                    <p className="text-[16px] font-normal leading-[30px] mt-3 mb-2">Ces mêmes langoustines qui servent à la préparation de notre soupe "La Trembladaise"...Et juste pour vous, un petit conseil pour bien réussir la cuisson de vos langoustines...</p>


                                    <Link to={{ pathname: "/pages/aujourdhui-cest-langoustine" }}>
                                        <ButtonSecondary className="">Lire la suite</ButtonSecondary>
                                    </Link>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* OUR TEAMS  */}


        </div>
    );
}

export default AuFilDesJours;
