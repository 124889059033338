import { FC, useCallback, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BagIcon from "components/BagIcon";
import NcInputNumber from "components/NcInputNumber";
import { SparklesIcon } from "@heroicons/react/24/outline";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import { useHistory, useParams } from "react-router-dom";
import { getProductByCategorie, getProductBySlug } from "actions/products";
import PlaceIcon from "shared/NcImage/PlaceIcon";
import Page404 from "containers/Page404/Page404";
import { Helmet } from "react-helmet";
import { addProductInCart, addProductToAnonymousCart, createAnonymousCart } from "actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { addProductInWishlist, removeProductInWishlist } from "actions/wishlist";
import { Transition } from "@headlessui/react";
import { IMG_LINK } from "config/key";
import ImageViewer from 'react-simple-image-viewer';
import { NotificationManager } from 'react-notifications';
import { BORDERS } from "config/theme";
import { getCategoryById } from "actions/categories";
import AccordionInfo from "./AccordionInfo";
import moment from "moment";
import times from "images/times.svg";

export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {
  const [productFound, setProductFound] = useState<boolean>();
  const [product, setProduct] = useState<any>([]);
  const [defaultVariant, setDefaultVariant] = useState<any>([]);
  const priceVariant: any = []
  const params: { slug: string } = useParams();
  const [allProduct, setAllProduct] = useState<any>();
  const [variantsObj, setVariantsObj] = useState<any>({});
  const [choosenProp, setchoosenProp] = useState<any>({});
  const [availableVar, setAvailableVar] = useState<any>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const _ = require('lodash');
  const dispatch = useDispatch();
  const { wishlist }: any = useSelector(state => state);
  const { auth }: any = useSelector(state => state);
  const { cart }: any = useSelector(state => state);
  const history = useHistory();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [listImages, setListImages]: any = useState([]);

  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProductBySlug(params.slug).then((result) => {
        getCategoryChain(result?.CategoriesString[0]?._id)
        setProductFound(true);
        setProduct(result);


        if (result) {
          var defaultVariant = result.Variants.find((item: any) => item._id == result.defaultVariantId);
          setDefaultVariant(defaultVariant)

          setchoosenProp(defaultVariant?.properties)

          getProductByCategorie(result?.CategoriesString[0]?._id).then((result: any[]) => {

            setAllProduct(result.filter(function (item: any) {
              return item.slug != params.slug;
            }))
          })
        }
      }).catch(() => {
        setProductFound(false);
      })
    }

    fetchData()
  }, [params.slug]);

  useEffect(() => {
    const img = ['image2Id', 'image3Id', 'image4Id', 'image5Id'];

    if (defaultVariant?.images) {
      let tempArr: any = []
      Object.keys(defaultVariant?.images).map((it: any) => {
        for (let index = 0; index < img.length; index++) {
          if (it === img[index]) {
            tempArr.push(IMG_LINK + defaultVariant?.images[it])
          }
        }
      })
      setListImages(tempArr)
    }
  }, [defaultVariant])

  const notifyAddTocart = () => {
    toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <div className="flex justify-between">
            <p className="block text-base font-semibold leading-none">
              Ajouter au panier!
            </p>

            <a className="relative top-[-.3rem] cursor-pointer" onClick={() => toast.dismiss()}>
              <img width={25} height={25} src={times} />
            </a>
          </div>

          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify()}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 3000 }
    );
  };

  const renderProductCartOnNotify = () => {
    return (
      <div className="flex ">
        <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          {defaultVariant?.images?.image1Id
            ?
            <img
              src={IMG_LINK + defaultVariant?.images?.image1Id}
              className="w-full h-full rounded object-cover"
              alt="product detail 1"
            />
            :
            <div
              className={`${className} flex items-center h-full w-full justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}>
              <div className="h-2/4 w-full h-full max-w-[50%]">
                <PlaceIcon />
              </div>
            </div>
          }
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{product.name}</h3>
                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  {choosenProp &&
                    <>
                      {Object.keys(choosenProp).map((variant: any, index: number) => (
                        <span key={index}><span className="capitalize">{variant}</span> {choosenProp[variant]}</span>
                      ))}
                    </>
                  }

                </p>
              </div>


              {!defaultVariant?.rebatedPrice

                ? <Prices
                  contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold whitespace-nowrap"
                  price={defaultVariant?.price}
                />
                : <div className="flex flex-row">
                  <Prices
                    contentClass="py-1 px-1 md:py-1.5 md:px-3 text-lg font-semibold whitespace-nowrap"
                    price={defaultVariant?.rebatedPrice}
                  />

                  <Prices
                    className="text-[red]"
                    contentClass="py-1 md:py-1.5 ml-2 md:px-3 text-lg font-semibold line-through text-red-500 whitespace-nowrap"
                    price={defaultVariant?.price}
                  />
                </div>

              }

            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">Quantité: x{quantity}</p>

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={(e) => {
                  e.preventDefault();
                  history.push("../cart")
                }}
              >
                Voir panier
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (product?.Variants) {
      fillVariantObj(product.Variants);

      var defaultVariant = product.Variants.find((item: any) => item._id == product.defaultVariantId);
      setDefaultVariant(defaultVariant);


      setchoosenProp(defaultVariant?.properties)
    }

  }, [product]);

  const fillVariantObj = (variants: any) => {
    let variantsObj: any = {};


    variants.forEach((element: { _id: string | number; properties: any; }) => {
      variantsObj[element._id] = element.properties
    });

    setVariantsObj(variantsObj)
  }

  useEffect(() => {
    let availableVar = [];

    for (const variantsObjKey in variantsObj) {

      const variantsObjElement = variantsObj[variantsObjKey];
      let flag = true

      for (const choosenPropKey in choosenProp) {
        const choosenPropElement = choosenProp[choosenPropKey];

        if (variantsObjElement[choosenPropKey] != choosenPropElement) {
          flag = false
        }
      }

      if (flag) { availableVar.push(variantsObjKey) }
    }

    setAvailableVar(availableVar)

  }, [choosenProp])

  const testFnc = (propKey: any, propValue: any) => {

    if (choosenProp[propKey] === propValue) {
      return "border-[1px] border-[#ff9900] text-[black]"
    }

    return 'border-[1px] border-gray-200 text-[black]'
  }

  const handlePushVariant = (variant: any, key: string) => {
    if (choosenProp[key] && choosenProp[key] == variant) {
      let newChoosenProp = choosenProp
      setchoosenProp({ ...newChoosenProp })
    } else {
      setchoosenProp({ ...choosenProp, [key]: variant })
    }
  }

  const renderVariants = () => {

    if (product?.Variants && Array.isArray(product.Variants) && product.Variants.length > 0) {
      let arr: any = [];
      let propArr: any = {};

      product.Variants.map((variant: any) => {
        priceVariant.push(variant.price);

        if (variant?.properties) {
          Object.keys(variant.properties).map((prop) => {
            if (!arr.includes(prop))
              arr.push(prop)
          })
        }
      })

      arr.map((propertieName: string) => {
        propArr[propertieName] = []
      })

      arr.map((propertieName: string) => {
        product.Variants.map((variant: any) => {
          Object.keys(variant.properties).map((it) => {
            if (propertieName === it) {
              propArr[it].push(variant.properties[it])
            }
          })
        })
      })

      for (const propertie in propArr) {
        let uniqueChars = propArr[propertie].filter((element: any, index: any) => {
          return propArr[propertie].indexOf(element) === index;
        });

        propArr[propertie] = uniqueChars
      }

      return (
        <>
          {Object.keys(propArr).map((key, index) => (
            <div key={index} className="mt-3 mb-3">
              <div className="mr-2 text-[14px] capitalize mt-[.3rem] font-[500]">{key}:</div>
              <div className="flex flex-row">
                {Object.values(propArr[key]).map((it: any, index: number) => (
                  <div className="relative">
                    <div onClick={() => handlePushVariant(it, key)} key={index} style={{ borderRadius: BORDERS.buttonVariant }} className={`cursor-pointer mt-3 relative h-10 sm:h-11 flex items-center justify-center 
                  text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 px-6 mr-4 ${testFnc(key, it)} `}>

                      {it}
                    </div>
                    {choosenProp[key] === it &&
                      <div className="m-2 flex h-5 w-5 items-center absolute top-[-5px] right-[-1px] justify-center rounded-full bg-[#2ecc71]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 70.761 49.778">
                          <path fill="white" id="Check-Icon-09iujhd" d="M115.739,61.78,78.282,99.237a5.655,5.655,0,0,1-8.005,0L49.3,78.257a5.653,5.653,0,0,1,7.994-7.994L74.279,87.24l33.455-33.466a5.661,5.661,0,0,1,8.005,8.005" transform="translate(-47.133 -51.62)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" />
                        </svg>
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )
    }
  }

  useEffect(() => {

    const findDefaultVariant = () => {
      const matchingVariant = product?.Variants?.find((variant: any) => {
        for (const key in choosenProp) {
          if (choosenProp[key] !== variant.properties[key]) {
            return false;
          }
        }
        return true;
      });

      if (matchingVariant) {
        setDefaultVariant(matchingVariant);
      }
    };

    findDefaultVariant();
  }, [choosenProp, product.Variants]);


  const handleAddProductInCart = async () => {
    const itemId = defaultVariant?._id

    if (product.simpleItem !== true) {

      if (!auth?.token) {
        if (Object.keys(cart).length === 0) {
          await dispatch(createAnonymousCart()).then(async (res: any) => {
            await dispatch(addProductToAnonymousCart(res._id, itemId, quantity)).then((test: any) => {
              notifyAddTocart();
            })
          })
        } else {
          await dispatch(addProductToAnonymousCart(cart._id, itemId, quantity)).then(() => {
            notifyAddTocart();
          }).catch((err: any) => {
            NotificationManager.error(err.response.data.error, '');
          })
        }
      } else {
        await dispatch(addProductInCart(itemId, quantity)).then((res: any) => {
          notifyAddTocart();
        }).catch((err: any) => {
          NotificationManager.error(err.response.data.error, '');
        })
      }

    } else {
      if (!auth?.token) {
        if (Object.keys(cart).length === 0) {
          await dispatch(createAnonymousCart()).then(async (res: any) => {
            await dispatch(addProductToAnonymousCart(res._id, itemId, quantity));
          })
        } else {
          await dispatch(addProductToAnonymousCart(cart._id, itemId, quantity)).then(() => {
            notifyAddTocart();
          }).catch((err: any) => {
            NotificationManager.error(err.response.data.error, '');
          })
        }
      } else {
        await dispatch(addProductInCart(itemId, quantity)).then(() => {
          notifyAddTocart();
        }).catch((err: any) => {
          NotificationManager.error(err.response.data.error, '');
        })
      }
    }
  }

  const renderPictures = () => {
    if (listImages) {
      return (
        <>
          {Object.keys(listImages).map((img: any, index: number) => (
            <div className="mr-4" key={index}>
              {img !== 'image1Id' &&
                <img
                  onClick={() => openImageViewer(index)}
                  className="rounded object-cover w-[115px] mb-3 cursor-pointer border-[1px] border-[#ff9900]"
                  src={listImages[index]}
                />
              }
            </div>
          ))}

        </>
      )
    }
  }

  const renderSectionContent = () => {

    return (
      <div>
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {product.name}
          </h2>

          <div className="flex items-center mt-2">

            {defaultVariant?.rebatedPrice
              ? <>
                <Prices
                  contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold"
                  price={defaultVariant.rebatedPrice}
                />

                <Prices
                  className="text-[red] ml-2"
                  contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold line-through text-red-500"
                  price={defaultVariant.price}
                />
              </>

              : <Prices
                contentClass="py-1 md:py-1.5 md:px-3 text-lg font-semibold"
                price={defaultVariant.price}
              />
            }

            {/* <span className="font-semibold ml-1 mr-4 underline text-[11px]">{(defaultVariant.price / (defaultVariant.weight / 1000)).toFixed(2)} € / kg</span> */}

            <div>
              <span className={`font-[500] flex flex-row ml-2 px-1 text-[12px] rounded ${defaultVariant.quantity <= 0 ? 'bg-[#e74c3c] text-[white]' : 'bg-[#2ecc71] text-[white]'}`}>
                {defaultVariant.quantity <= 0 ? 'Rupture de stock' : 'En stock'}
                {defaultVariant?.quantity < 10 &&
                  <span className="ml-1">({defaultVariant?.quantity} produits restants)</span>
                }
              </span>

            </div>


          </div>
        </div>

        <div className="mt-4 mb-8">
          <h3 className="text-[#7B768D] text-[16px]">
            {product?.shortDescription}
          </h3>
        </div>


        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {!product?.simpleItem &&
          <div className="mt-8">{renderVariants()}</div>
        }

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5 mt-8">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5" style={{ borderRadius: BORDERS?.inputQuantityNumber }}>
            <NcInputNumber
              defaultValue={quantity}
              onChange={setQuantity}
            />
          </div>

          <ButtonPrimary
            onClick={() => { handleAddProductInCart() }}
            className={`${defaultVariant.quantity <= 0 ? 'cursor-not-allowed' : 'cursor-pointer'} flex-1 flex-shrink-0 `}
            disabled={defaultVariant.quantity <= 0}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">Ajouter au panier</span>
          </ButtonPrimary>

        </div>
        <hr className="2xl:!my-10 border-slate-200 dark:border-slate-700 xs:mt-6 xs:mb-6"></hr>

        <AccordionInfo data={product} />
      </div>
    );
  };

  const handleAddProductWishlist = async (itemId: string) => {
    if (!wishlist.hasOwnProperty(product._id)) {
      await dispatch(addProductInWishlist(itemId));
    } else {
      await dispatch(removeProductInWishlist(itemId));
    }
  }

  const getCategoryChain: any = async (categoryId: any) => {
    const category = await getCategoryById(categoryId);


    if (category.subCatOf) {
      return getCategoryChain(category.subCatOf);
    }
  };

  return (
    <>
      {productFound === false
        ? <Page404 />
        : productFound === true
          ?
          <>
            <>
              <Helmet>
                <title>{product?.metaTitle ? product?.metaTitle : "Produit détail"} | Conserverie Bertin</title>
                <meta name="description" content={product?.metaDescription ? product?.metaDescription : "Description en détail du produit"} />
              </Helmet>

              {isViewerOpen && (
                <ImageViewer
                  src={listImages}
                  currentIndex={currentImage}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                  disableScroll={true}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 999
                  }}
                />
              )}

              <div className={`nc-ProductDetailPage ${className}`}>
                {/* MAIN */}
                <div className="container mt-8">

                  {/* BREADCRUMB */}
                  <div className="mb-8 mt-16">
                    <span onClick={() => history.push({ pathname: '/' })} className={`text-[#555555] text-[14px] cursor-pointer`}>
                      Accueil
                      <span className="ml-1 mr-1">{'>'}</span>
                    </span>

                    <span className="font-semibold">{product?.name}</span>
                  </div>


                  <button onClick={() => window.history.back()} className="w-10 h-7 flex items-center justify-center rounded bg-slate-200 opacity-[.6] hover:opacity-[1] text-neutral-700 nc-shadow-lg ">
                    <span className="text-[20px] font-[500]">{"<"}</span>
                  </button>
                </div>



                <main className="mt-5 lg:mt-8">


                  <div className="lg:flex container">

                    {/* CONTENT */}

                    <div className="w-full lg:w-[55%] ">


                      {/* HEADING */}

                      <div className="relative">

                        <div className="aspect-w-16 aspect-h-16">

                          {defaultVariant.images.image1Id
                            ? <img
                              src={IMG_LINK + defaultVariant.images.image1Id}
                              className="w-full h-full object-cover rounded"
                              alt="product detail 1"
                            />
                            : <div className="w-full h-full object-cover rounded"
                            >
                              <div className=" flex items-center justify-center h-full bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                                <div className="">
                                  <PlaceIcon />
                                </div></div></div>
                          }


                          {auth.token !== null &&
                            <>
                              {auth.token !== undefined &&

                                <div onClick={() => handleAddProductWishlist(product?._id)} className="flex justify-end p-2">
                                  <button className={`w-9 h-9 flex items-center justify-center rounded-full bg-white dark:bg-slate-900 text-neutral-700 dark:text-slate-200 nc-shadow-lg ${className}`}>
                                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
                                      <path
                                        d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                                        stroke={wishlist.hasOwnProperty(product._id) ? 'red' : "currentColor"}
                                        fill={wishlist.hasOwnProperty(product._id) ? 'red' : "white"}
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              }
                            </>
                          }
                        </div>


                        {/* IS NEW ITEM */}
                        {moment.duration(moment(Date.now()).diff(moment(product?.createdAt))).asDays() <= 7 &&
                          <div className={'absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300'}>
                            <SparklesIcon className="w-3.5 h-3.5" />
                            <span className="ml-1 leading-none">Nouveauté</span>
                          </div>
                        }


                        <div className="flex relative top[-250px] justify-center lg:justify-start xl:justify-start md:justify-start">
                          {renderPictures()}
                        </div>

                      </div>


                    </div>



                    {/* SIDEBAR */}
                    <div className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10">
                      {renderSectionContent()}
                    </div>

                  </div>

                  {/* DETAIL AND REVIEW */}
                  <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">

                    {/* {renderDetailSection()} */}
                    <div className="container">

                      <hr className="border-slate-200 dark:border-slate-700" />
                    </div>

                    {allProduct &&
                      <SectionSliderProductCard
                        heading="Découvrez d'autres produits en optimisant les frais de port...."
                        subHeading=""
                        headingFontClassName="text-2xl font-semibold"
                        headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
                        className="pb-20"
                        data={allProduct}
                      />
                    }

                  </div>
                </main>
              </div>
            </>
          </>
          : <div style={{ height: window.innerHeight }} ></div>
      }

    </>
  );
};

export default ProductDetailPage;
