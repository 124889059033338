import axios from "axios";
import { API_ENDPOINT } from "../config/key";

export const apiClient = (contentType) => {
    const token = localStorage.getItem('token');
    
    const options = {
        baseURL: API_ENDPOINT,
        responseType: "json",

        headers: {
            post: { "Content-Type": contentType || "application/json" },
            Accept: "application/json",
            "locale": "FR",

            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept",
            'Access-Control-Allow-Credentials': "true",
            'x-access-token': token && token
        },
    };

    const instance = axios.create(options);

    return instance;
};
