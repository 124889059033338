import { Disclosure } from "@headlessui/react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { FC } from "react";

interface Props {
    panelClassName?: string;
    data?: any;
    defaultOpen?: any;
}

const AccordionInfo: FC<Props> = ({
    panelClassName = "p-4 pt-3 last:pb-0 text-slate-600 text-sm dark:text-slate-300 leading-6",
    data,
    defaultOpen = true,
}) => {
    return (
        <div>
            <div className="w-full rounded-2xl space-y-2.5">
                <Disclosure defaultOpen={defaultOpen}>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                                <span>Description</span>
                                {!open ? (
                                    <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                ) : (
                                    <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                )}
                            </Disclosure.Button>
                            <Disclosure.Panel
                                className={panelClassName}
                                as="div"
                            >
                                {data?.longDescription}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>


            {data?.specifications && Object.keys(data.specifications).length !== 0 && (
                <div className="w-full rounded-2xl space-y-2.5 mt-6">
                    <Disclosure defaultOpen={defaultOpen}>
                        {({ open }) => (
                            <>
                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75">
                                    <span>Caractéristiques</span>
                                    {!open ? (
                                        <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                    ) : (
                                        <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                    )}
                                </Disclosure.Button>
                                <Disclosure.Panel className={panelClassName} as="div">
                                    <ul className="list-disc ml-5">
                                        {Object.keys(data.specifications).map((key, index) => (
                                            <li key={index}>
                                                {key}: {data.specifications[key]}
                                            </li>
                                        ))}
                                    </ul>
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                </div>
            )}

        </div>
    );
};

export default AccordionInfo;
