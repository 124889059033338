import { apiClient } from './apiClient'

function getAllCategories() {
    return apiClient().get('/categories')
}

function getAllCategoriesFlat() {
    return apiClient().get('/categories/flat')
}

function getCategoryById(catId: string) {
    return apiClient().get('/categories/' + catId)
}

export default {
    getAllCategories,
    getCategoryById,
    getAllCategoriesFlat
}
