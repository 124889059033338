import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ProductCard from "components/ProductCard";
import SidebarFilters from "./SidebarFilters";
import { getAllCategories } from "actions/categories";
import { getAllProducts } from "actions/products";
import Checkbox from "shared/Checkbox/Checkbox";
import { Alert } from "shared/Alert/Alert";
import ReactPaginate from 'react-paginate';

export interface PageCollection2Props {
  className?: string;
}

const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const [products, setProducts] = useState<any>([]);
  const arrCat = [];
  const arr = [];
  const [categoriesState, setCategoriesState] = useState<Array<string>>([]);
  const [categories, setCategories] = useState([]);
  const [arrCollapseCat, setArrCollapseCat] = useState<any>([]);
  const [refreshCounter, setRefreshCounter]: any = useState(0)
  const [allProducts$, setAllProducts] = useState<any>([]);

  // PAGINATION
  let itemsPerPage = 12;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = products?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products?.length / itemsPerPage);
  // PAGINATION 


  useEffect(() => {
    getAllProducts().then((result) => {
      setProducts(result);
      setAllProducts(result);
    })
  }, [])

  useEffect(() => {
    getAllCategories().then((result) => {
      setCategories(result);
    })
  }, [])


  useEffect(() => {
    if (categoriesState.length !== 0) {

      let arr: any = []

      categoriesState.map((catId: string) => {
        allProducts$.find((obj: any) => {
          if (obj.categories == catId) {
            arr.push(obj)
          }
        })
      })

      setProducts(arr);
    }

    if (categoriesState.length === 0) {
      getAllProducts().then((result) => {
        setProducts(result);
      })
    }

  }, [categoriesState])

  const handleChangeCategories = (checked: boolean, _id: string) => {
    if (checked) {
      setCategoriesState([...categoriesState, _id]);

    } else {
      setCategoriesState(categoriesState.filter((i) => i !== _id));
    }
  };

  const handleCollaspeCategory = (id: number) => {
    setArrCollapseCat((current: any) => [...current, id]);

    if (arrCollapseCat.indexOf(id) !== -1) {
      setArrCollapseCat((current: any) =>
        current.filter((element: any) => {
          return element !== id;
        }),
      );
    }
  }

  arr.push(categories)

  for (var index = 0; index < arr.length; index++) {
    if (!!arr[index]) {
      arrCat.push(Object.values(arr[index]));
    }
  }

  const Children = (props: any) => (
    <>
      <ul>

        <div className="flex row mt-3">

            <Checkbox
              sizeClassName="w-5 h-5"
              labelClassName="text-sm font-normal"
              name={props.children.name}
              label={props.children.name}
              defaultChecked={categoriesState.includes(props.children._id)}
              onChange={(checked) => handleChangeCategories(checked, props.children._id)}
            />

            {/* <span className="ml-1">({props?.children?.itemNumber})</span> */}

            {props.children.subCat.length !== 0 &&
              <svg onClick={() => handleCollaspeCategory(props.children._id)} className={`w-6 h-6 cursor-pointer`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
              </svg>
            }



        </div>

        {(arrCollapseCat.indexOf(props.children._id) !== -1) &&
          <>
            {props.children.subCat && (
              <li key={props.children._id} className="ml-4">
                {renderChild(props.children.subCat)}
              </li>
            )}
          </>
        }
      </ul>
    </>
  );

  const renderChild = (item: any) => item.map((it: any, index: number) => <Children key={index} children={it} />);

  const handlePagePaginateClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % products?.length;

    setItemOffset(newOffset);
  };

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <Helmet>
        <title>Milango | Ciseco Ecommerce Template</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Man collection
            </h2>

            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              We not only help you design exceptional products, but also make it
              easy for you to share your designs with more like-minded people.
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* LOOP ITEMS */}
            <div className="flex flex-col lg:flex-row">
              <div className="lg:w-1/3 xl:w-1/4 pr-4">
                <div className="relative flex flex-col pb-8 border-[1px] border-[transparent] border-b-[#e2e8f0]">
                  <h3 className="font-semibold mb-2.5">Categories</h3>
                  {renderChild(arrCat[0])}
                </div>
                
                <SidebarFilters />
              </div>
              <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
              <div className="flex-1 ">
                <div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10">

                  {currentItems.map((item: any, index: number) => (
                    <ProductCard refreshFnc={() => setRefreshCounter(refreshCounter + 1)} data={item} key={index} />
                  ))}
                </div>

                {products.length !== 0 &&
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePagePaginateClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="<"
                    containerClassName={'containerPaginate'}
                    previousClassName="previousBtnPaginate"
                    nextClassName="nextBtnPaginate"
                    activeClassName="activeBtnPaginate"
                    pageClassName="pageBtnPaginate"
                  />
                }

                {products.length === 0 &&
                  <Alert type="warning" title="Attention" description="Malheureusement, nous ne trouvons aucun produit correspondant à vos filtres." />
                }
              </div>

            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PageCollection2;
