import { GET_WISHLIST } from '../../actions/types'

const defaultState = {}

export default (state = defaultState, action: any) => {
    const { type, payload, error = {} } = action

    switch (type) {
        case GET_WISHLIST:
            return { ...payload };
        default:
            return state
    }
}