import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { useHistory } from "react-router-dom";

const Page404: React.FC = () => {
  const history = useHistory();

  return (
    <div className="nc-Page404">
      <Helmet>
        <title>404 || Conserverie Bertin</title>
      </Helmet>
      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I404Png} />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          LA PAGE QUE VOUS RECHERCHEZ N'EXISTE PAS.{" "}
          </span>
          <div className="pt-8">
            <ButtonPrimary onClick={() => history.push({ pathname: '/' })}>Revenir à l'accueil</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  )
}

export default Page404;
