import api from "../api/stripe";

// GET STRIPE PUBLIC KEY
export const getStripePublicKey = () => {
    return api
        .getStripePublicKey()
        .then(async (result: any) => {
            if (result) {
                return result?.data
            }
        })
};


// GET STRIPE CLIENT SECRET
export const getStripeClientSecret = () => {
    return api
        .getStripeClientSecret()
        .then(async (result: any) => {
            if (result) {
                return result?.data
            }
        })
};