import { apiClient } from './apiClient'

function getAllProducts() {
    return apiClient().get('/item/all')
}

function getAllProductsFilter(data: any) {
    return apiClient().post('/item/all', data)
}

function getAllFilter(data: any) {
    return apiClient().post('/item/filter', data)
}

function getProductById(id: string) {
    return apiClient().get('/item/' + id)
}

function getProductBySlug(slug: string) {
    return apiClient().get('/item/slug/' + slug)
}

function getProductByCategorie(id: string) {
    return apiClient().get('item/categories/' + id)
}

function getAllNewProduct() {
    return apiClient().get('item/new/');
}
function getBestSeller() {
    return apiClient().get("item/bestseller/10");
}

export default {
    getAllProducts,
    getProductById,
    getProductByCategorie,
    getProductBySlug,
    getAllNewProduct,
    getBestSeller,
    getAllProductsFilter,
    getAllFilter
}
