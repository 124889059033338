import { apiClient } from './apiClient'

function addProductInCart(itemId: string, quantity: number) {
    return apiClient().post('/cart', { itemId: itemId, quantity: quantity })
}

function getProductInCart() {
    return apiClient().get('/cart')
}

function patchCart(data: any) {
    return apiClient().patch('/cart', data)
}

function deleteProductInCart(itemId: string) {
    return apiClient().delete('/cart/' + itemId)
}

function editProductInCart(itemId: string, newQuantity: number) {
    return apiClient().patch('/cart/' + itemId + "/" + newQuantity)
}

function createAnonymousCart() {
    return apiClient().post("/cart/anonymous/")
}

function addProductToAnonymousCart(anonymousCartId: string, itemId: string, quantity: number) {
    return apiClient().post("/cart/anonymous/" + anonymousCartId, { itemId: itemId, quantity: quantity })
}

function getAnonymousCart(anonymousCartId: string) {
    return apiClient().get("/cart/anonymous/" + anonymousCartId)
}

function editProductInAnonymousCart(anonymousCartId: string, itemId: string, newQuantity: number) {
    return apiClient().patch('/cart/anonymous/' + anonymousCartId + '/' + itemId + "/" + newQuantity)
}

function deleteProductInAnonymousCart(anonymousCartId: string, itemId: string) {
    return apiClient().delete('/cart/anonymous/' + anonymousCartId + '/' + itemId)
}

function mergeAnonymousCart(anonymousCartId: string) {
    return apiClient().post('/cart/join/' + anonymousCartId)
}

function addDiscountCode(discountCode: string) {
    return apiClient().post('/cart/discount/' + discountCode)
}

function deleteDiscountCode() {
    return apiClient().delete('/cart/discount/')
}

function patchCartAnonymousCart(anonymousCartId: any, data: any) {
    return apiClient().patch('/cart/anonymous/' + anonymousCartId, data)
}

export default {
    addProductInCart,
    getProductInCart,
    deleteProductInCart,
    editProductInCart,
    getAnonymousCart,
    addProductToAnonymousCart,
    editProductInAnonymousCart,
    deleteProductInAnonymousCart,
    createAnonymousCart,
    mergeAnonymousCart,
    addDiscountCode,
    deleteDiscountCode,
    patchCart,
    patchCartAnonymousCart
}
